import { styled } from "@mui/material/styles";

export const InputWrapper = styled("div")(
  ({ theme }) => `
    // border: 1px solid ${
      theme.palette.mode === "dark" ? "#434343" : "#d9d9d9"
    };
    // background-color: ${theme.palette.mode === "dark" ? "#141414" : "#fff"};
    // border-radius: 4px;
    // padding: 1px;
    display: flex;
    flex-wrap: wrap;
  
    // &:hover {
    //   border-color: #ff4848;
    // }
  
    & input {
      background-color: ${theme.palette.mode === "dark" ? "#141414" : "#fff"};
      color: ${
        theme.palette.mode === "dark"
          ? "rgba(255,255,255,0.65)"
          : "rgba(0,0,0,.85)"
      };
      height: 30px;
      box-sizing: border-box;
      padding: 4px 6px;
      width: 0;
      min-width: 30px;
      flex-grow: 1;
      border: 0;
      margin: 0;
      outline: 0;
    }
  `
);
