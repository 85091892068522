import {
  EmailVerification,
  ForgotPasswordScreen,
  GoogleRegisterScreen,
  LoginScreen,
  RegisterScreen,
  UnsubscribeScreen,
  UpdatePasswordScreen,
} from "../screens";
import ComponentScreen from "../screens/ComponentScreen/ComponentScreen";
import BlockedTenantScreen from "../screens/DefaultScreens/BlockedTenantScreen";
import accountFlagged from "../screens/DefaultScreens/accountFlagged";
import CalendarScreen from "../screens/MeetingScreen/Calendar/CalendarScreen";
import CancelMeetingScreen from "../screens/MeetingScreen/Meeting/CancelMeeting"
import {
  EMAIL_VERIFICATION_PATH,
  FORGOT_PASSWORD_MAIL_SEND_PATH,
  FORGOT_PASSWORD_PATH,
  LOGIN_PATH,
  LOGIN_PATH_WITH_EMAIL,
  REGISTER_PATH_WITH_GOOGLE,
  REGISTER_PATH_WITH_INVITE,
  UNSUBSCRIBE_SUCCESSFUL_PATH,
  CALENDAR_PATH,
  CALENDAR_PATH_CLONE,
  ERROR_PAGE_404,
  COMPONENT,
  CANCEl_MEETING_PATH,
  INVALID_TENANT_ROUTE_PATH,
  BLOCKED_TENANT_ROUTE_PATH,
} from "../utils/constants/routes";
import errorPage from "../utils/error_Pages/errorpage";

export const authRoutes = [
  {
    path: LOGIN_PATH,
    component: LoginScreen,
  },
  {
    path: LOGIN_PATH_WITH_EMAIL,
    component: LoginScreen,
  },
  {
    path: REGISTER_PATH_WITH_INVITE,
    component: RegisterScreen,
  },
  {
    path: REGISTER_PATH_WITH_GOOGLE,
    component: GoogleRegisterScreen,
  },
  {
    path: FORGOT_PASSWORD_PATH,
    component: ForgotPasswordScreen,
  },
  {
    path: EMAIL_VERIFICATION_PATH,
    component: EmailVerification,
  },
  {
    path: FORGOT_PASSWORD_MAIL_SEND_PATH,
    component: UpdatePasswordScreen,
  },
  {
    path: UNSUBSCRIBE_SUCCESSFUL_PATH,
    component: UnsubscribeScreen,
  },
  {
    path: CALENDAR_PATH,
    component: CalendarScreen,
  },

  {
    path: CALENDAR_PATH_CLONE,
    component: CalendarScreen,
  },
  {
    path: ERROR_PAGE_404,
    component: errorPage,
  },
  {
    path: COMPONENT,
    component: ComponentScreen,
  },
  {
    path: CANCEl_MEETING_PATH,
    component: CancelMeetingScreen,
  },
  {
    path: INVALID_TENANT_ROUTE_PATH,
    component: accountFlagged,
  },
  {
    path: BLOCKED_TENANT_ROUTE_PATH,
    component: BlockedTenantScreen,
  },

];
