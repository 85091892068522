import React from "react";
import Typography from "../../components/Typography/Typography";
import DateRangePicker from "../../components/DateRangePicker/DateRangePicker/DateRangePicker";
import { HtmlEditor } from "../../components";
const ComponentScreen = () => {

  const submitHandler = (data) => {
    console.log("Dataa____________ ", data);
  }

  return (
    <div style={{ padding: "20px" }}>
      <Typography size={"dxl"} color={"#f84848"} label="Component Screen" />
      <br />
      <br />

      {/* <DateRangePicker initDates={{ label: "custom_range", dates: { from: new Date(1710156067620), to: new Date(1710674538372) } }} onSubmit={submitHandler} onReset={submitHandler} /> */}
      <DateRangePicker initDates={{}} onSubmit={submitHandler} onReset={submitHandler} />
      <br />
      <HtmlEditor />
      <br />
    </div>
  )
}

export default ComponentScreen