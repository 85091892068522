import React, { useEffect } from "react";
import { InputWrapper, Root, StyledTag, Listbox } from ".";
import { useAutocomplete } from "@mui/base/AutocompleteUnstyled";
import { useDispatch, useSelector } from "react-redux";
import {
  allFilters,
  filterHeaderSearch,
} from "../../redux/actions/filterActions";
import { useLocation, useNavigate, createSearchParams } from "react-router-dom";

import { Box } from "@mui/system";
import { styles } from "./styles";
import { allSalesFilters } from "../../redux/actions/salesTableAction";
import { CRM_PATH } from "../../utils/constants/routes";

const filterOutSuggestions = (payload, array, key) => {
  for (let i = 0; i < array?.length; i++) {
    payload = payload?.filter((item) => item[key] !== array[i][key]);
  }
  return payload;
};

const CustomAutoComplete = ({
  endPoint,
  type,
  filter,
  keyType = "name",
  filterType = "name",
}) => {
  const getDefaultValues = (data) => {
    let key = filter + type;
    const localData = localStorage.getItem(key);
    if (keyType === "id" && JSON.parse(localData)) {
      const parsedData = JSON.parse(localData);

      let result = [];
      for (let i = 0; i < parsedData?.length; i++) {
        result.push({ name: parsedData[i]?.name, id: parsedData[i]?.id });
      }
      return result;
    } else {
      let result = [];
      for (let i = 0; i < data?.length; i++) {
        result.push({ name: data[i] });
      }
      return result;
    }
  };

  const allFiltersSelector = useSelector((state) => state.allFilters);

  const navigate = useNavigate();
  const location = useLocation();
  const selector = useSelector((state) => state);
  const { error, payload, loading } = selector.filterHeaderSearch;
  const dispatch = useDispatch();

  const defaultValues = getDefaultValues(
    allFiltersSelector?.payload[filter + type]
  );

  const {
    getRootProps,
    getInputProps,
    getTagProps,
    getListboxProps,
    getOptionProps,
    groupedOptions,
    value,
    setAnchorEl,
  } = useAutocomplete({
    id: "customized-hook",
    multiple: true,
    defaultValue: defaultValues,
    options:
      filterOutSuggestions(payload?.data || [], defaultValues, keyType) || [],
    getOptionLabel: (option) => option?.["name"],
  });

  let inputValue = getInputProps()?.value;

  useEffect(() => {
    if (value?.length >= 0) {
      const params = value?.map((x) => x[filterType]);
      dispatch(allFilters({ [filter + type]: params }));

      navigate({
        pathname: location.pathname,
        search: createSearchParams({
          ...allFiltersSelector?.payload,
          [filter + type]: params,
        }).toString(),
      });
    }

    if (inputValue.length >= 3) {
      dispatch(
        filterHeaderSearch(endPoint, `search=${inputValue}&page=1`, navigate)
      );
    }
  }, [inputValue, error, endPoint, value, dispatch, navigate]);

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  return (
    <Root>
      <div {...getRootProps()}>
        <InputWrapper ref={setAnchorEl}>
          {value?.map((option, index) => (
            <StyledTag
              label={option[keyType] || ""}
              key={index}
              {...getTagProps({ index })}
            />
          ))}
          <Box
            component="form"
            onSubmit={handleSubmit}
            sx={styles.inputContainer}
          >
            <input
              onKeyDown={(e) => e.stopPropagation()}
              style={styles.input}
              placeholder="Search..."
              {...getInputProps()}
            />
          </Box>
        </InputWrapper>
      </div>

      {getInputProps()?.value?.length >= 3 ? (
        groupedOptions.length === 0 && loading ? (
          <span style={{ margin: "12px", lineHeight: 2 }}>Loading...</span>
        ) : (
          <Listbox {...getListboxProps()} sx={styles.listBox}>
            {groupedOptions.map((option, index) => (
              <div key={index}>
                <li {...getOptionProps({ option, index })}>
                  <span>{option?.[keyType]}</span>
                </li>
              </div>
            ))}
          </Listbox>
        )
      ) : null}
    </Root>
  );
};

export default React.memo(CustomAutoComplete);
