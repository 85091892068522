import React, { useEffect, useRef, useState } from "react";
import { Grid, TextField } from "@mui/material";
import ReCAPTCHA from "react-google-recaptcha";
import {
  CALENDAR,
  GOOGLE_ICON,
  MICROSOFT_IMAGE,
  NEW_LOGIN_REACHIQ_LOGO,
} from "../../../utils/constants/assets";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { StaticDatePicker } from "@mui/x-date-pickers/StaticDatePicker";
import { styles } from "./styles";
import { Box } from "@mui/system";
import {
  EMITTER_ERROR,
  MONTH_INDEX,
  TIMEZONE_LIST,
} from "../../../utils/constants";
import {
  HOST_URL,
  REACHIQ_HOME,
  REGISTRATION_LINK,
  meetingsEndpoint,
  protocol,
} from "../../../utils/constants/httpConstants";
import { postRequest } from "../../../Apis";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { ErrorMessage, FullScreenLoader } from "../../../components";
import "../../../../index.css";
import { useSelector } from "react-redux";
import { Theme } from "../../../theme";
import { emailValidation } from "../../../utils/validations";
import EventEmitter from "../../../utils/emitter/EventEmitter";
import { Button } from "../../../components/Buttons";
import { STRINGS } from "../strings";
import { BASE, GREY, PRIMARY } from "../../../utils/constants/colors";
import { ICON_LABELS, fontSize, fontWeight } from "../../../utils/constants/UI";
import TypographyInline from "../../../components/Typography/TypographyInline";
import InputField from "../../../components/InputFields/InputField";
import IconComponent from "../../../components/Icon/IconComponent";
import Image from "../../../components/Image/Image";
import { Mixpanel } from "../../../Apis/Mixpanel";
import { AutoCompleteCustom } from "../../../components/Autocomplete/AutoCompleteCustom";
import SkeletonLoader from "../../../components/Loaders/SkeletonLoader";
import AvatarName from "../../../components/Avatar/AvatarName";
import AvatarOutlined from "../../../components/Avatar/AvatarOutlined";
import InvalidRouteScreen from "../../DefaultScreens/InvalidRouteScreen";
import {
  CalendarRequiredValidation,
  calendarValidation,
} from "../../../utils/validations/inputValidations";
import { verifyDomain } from "../../../redux/actions/authActions";
import {
  handleGoogleAuthentication,
  handleMicrosoftAuthentication,
} from "../../../utils/helpers";

const CalendarScreen = () => {
  let params = useParams();
  const Location = useLocation();
  const Tokens = Location?.search?.split(STRINGS.EVENT_LOC_ID);
  const TokenData = Location?.search?.split(STRINGS.TOKEN_ID);
  const customIdUrl = Location?.pathname?.split("/");
  const [error, setError] = useState(false);
  const [radioId, setRadioId] = useState(null);
  const [toDate, setToDate] = useState(new Date());

  const navigate = useNavigate();
  const [activeStep, setActiveStep] = useState(0);
  const [date, changeDate] = useState(new Date());
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  let offset = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const timezone_objChange = TIMEZONE_LIST?.filter(
    (item) => item?.id === offset
  );
  const [mainLoader, setMainLoader] = useState(true);
  const [valueStatestime, setValueStatestime] = useState(
    timezone_objChange[0]?.label || []
  );
  const [customWeek, setCustomWeek] = useState("");
  const [minDateCal, setMinDateCal] = useState(new Date());
  const [maxDateCal, setMaxDateCal] = useState(new Date());
  const [timeZone, setTimeZone] = useState([timezone_objChange[0]?.id] || []);
  const [response1, setResponse1] = useState([]);
  const [colorClick, setColorClick] = useState(0);
  const [colorClicked, setColorClicked] = useState("");
  const [loader, setLoader] = useState(false);
  const [email, setEmail] = useState("");
  const [calendar, setCalendar] = useState([]);
  const [hourMinutes, setHourMinutes] = useState("");
  const [token, setToken] = useState(false);
  const [phone, setPhone] = useState("");
  const [mobile, setMobile] = useState("");
  const [street, setStreet] = useState("");
  const [durationSet, setDurationSet] = useState("");
  const [timeselected, setTimeSelected] = useState("");
  const [validations, setValidations] = useState({ captcha: "" });
  const [availability, setAvailability] = useState([]);
  const [completeTime, setCompleteTime] = useState("");
  const [time, setTime] = useState("");
  const [timeValue, setTimeValue] = useState("8 AM");
  const [name, setName] = useState("");
  const captchaRef = useRef(null);
  const [inputError, setInputError] = useState({});
  const [fromDate, setFromDate] = useState(new Date());
  const [timezoneList, setTimezoneList] = useState(TIMEZONE_LIST);

  const handleReachIQ = () => {
    window.open(REACHIQ_HOME, "_blank");
  };
  const arraySkeleton = [
    {
      id: 1,
      label: <SkeletonLoader skeletonHeight="55px" />,
    },
    { id: 2, label: <SkeletonLoader skeletonHeight="55px" /> },
    { id: 3, label: <SkeletonLoader skeletonHeight="55px" /> },
    { id: 4, label: <SkeletonLoader skeletonHeight="55px" /> },
    { id: 5, label: <SkeletonLoader skeletonHeight="55px" /> },
  ];

  const espConnectionList = [
    {
      id: 1,
      label: STRINGS.CONNECT_GOOGLE,
      icon: GOOGLE_ICON,
    },
    {
      id: 2,
      label: STRINGS.CONNECT_OUTLOOK,
      icon: MICROSOFT_IMAGE,
    },
  ];
  const BrandListSelector = useSelector((state) => state.getBrandKitList);
  const data1 = calendar || [];
  const { loading } = BrandListSelector;
  const props = {
    background: data1?.background_color,
  };

  const classes = styles(props);

  const handleTime = (obj, index) => {
    setTime(obj?.utc);
    setTimeValue(obj?.time_in_selected_TZ);
    setTimeSelected(obj?.time_in_selected_TZ);
    setCompleteTime(obj?.complete_time_TZ);
    setColorClicked(index);

    setActiveStep(1);
  };
  const a = calendar?.duration_options;

  const DurationSort = a?.sort((a, b) => a?.id - b?.id);

  useEffect(() => {
    (async () => {
      if (Tokens[1] && durationSet) {
        const DurationSort = calendar?.duration_options?.sort(
          (a, b) => a?.id - b?.id
        );

        const RestructuredDuration = DurationSort?.map((i, index) => {
          if (i?.id === durationSet) {
            return index;
          }
        });

        const nonUndefinedValues = RestructuredDuration?.filter(
          (item) => item !== undefined
        );
        setColorClick(nonUndefinedValues?.[0]);
      } else {
        setColorClick(0);
      }
    })();
  }, [calendar?.duration_options, durationSet]);
  useEffect(() => {
    const today = new Date();

    setMinDateCal(today);
    const currentDay = today?.getDay();
    const maxDate = new Date(today);
    if (radioId === 0) {
      const daysRemainingInWeek = 7 - currentDay;
      const weekk = customWeek;
      const numberOfWeeks = weekk - 1;
      maxDate?.setDate(
        today?.getDate() + (daysRemainingInWeek + numberOfWeeks * 7)
      );
      setMaxDateCal(new Date(maxDate));
    } else {
      const newMinDate = today;
      if (newMinDate > new Date(fromDate)) {
        setMinDateCal(new Date(fromDate));
      }
      setMaxDateCal(new Date(toDate));
    }
  }, [customWeek, radioId, toDate, fromDate]);
  const convertFormat = (value) => {
    let tempDateTime = new Date(value);
    let month = MONTH_INDEX[tempDateTime.getMonth()] || "--";
    let date = tempDateTime.getDate() || "--";
    let year = tempDateTime.getFullYear() || "--";
    let dateTime = `${month} ${date}, ${year}`;
    return dateTime;
  };
  const handleCalendarEventApi = async (e) => {
    e?.preventDefault();
    const firstValidation = calendarValidation(firstName);
    const lastValidation = calendarValidation(lastName);
    const mobileValidation = CalendarRequiredValidation(
      mobile,
      data1?.dynamic_calendar[1]?.required[0]
    );
    const phoneValidation = CalendarRequiredValidation(
      phone,
      data1?.dynamic_calendar[0]?.required[0]
    );
    const streetValidation = CalendarRequiredValidation(
      street,
      data1?.dynamic_calendar[2]?.required[0]
    );

    if (
      firstValidation?.isValid &&
      lastValidation?.isValid &&
      mobileValidation?.isValid &&
      phoneValidation?.isValid &&
      streetValidation?.isValid
    ) {
      try {
        const validatedEmail = emailValidation(email);

        if (calendar?.captcha === true) {
          if (token && validatedEmail.isValid) {
            setValidations({
              email: "",
              captcha: "",
            });
            const timezone_obj = TIMEZONE_LIST?.filter(
              (item) => item.id === timeZone[0]
            );
            setLoader(true);

            const payload = {
              organizer_timezone: timezone_obj[0],
              custom_id: Object.values(params)[0],
              duration_options: hourMinutes,
              time: time,
              complete_time_TZ: completeTime,
              time_in_selected_TZ: timeselected,
              date: date,
              first_name: firstName,
              last_name: lastName,
              email: email,
              phone: phone,
              street: street,
              mobile: mobile,
              time_zone: timeZone,
              dynamic_calendar: calendar?.dynamic_calendar,
              location: calendar?.location,
              scheduler_timezone: timezone_obj[0],
              token: TokenData[1],
              recaptcha_token: token,
              captcha: calendar?.captcha,
              eventId: Tokens[1],
              imageURL: calendar?.imageURL,
            };
            if (Tokens[1]) {
              await postRequest(
                navigate,
                meetingsEndpoint.rescheduleMeeting,
                payload
              );
              setLoader(false);
              setActiveStep(activeStep + 1);
              Mixpanel.track(STRINGS.CALENDAR_CONFIM_BTN);
            } else {
              await postRequest(
                navigate,
                meetingsEndpoint.CreateCalendarEvent,
                payload
              );
              setLoader(false);
              setActiveStep(activeStep + 1);
              Mixpanel.track(STRINGS.CALENDAR_CONFIM_BTN);
            }
          } else {
            if (!token) {
              setValidations({
                captcha: STRINGS.GOOGLE_CAPTCHA,
              });
            } else {
              setValidations({
                email: validatedEmail.message,
                captcha: "",
              });
            }
          }
        } else {
          if (validatedEmail.isValid) {
            setValidations({
              email: "",
              captcha: "",
            });
            const timezone_obj = TIMEZONE_LIST?.filter(
              (item) => item.id === timeZone[0]
            );
            setLoader(true);
            const payload = {
              complete_time_TZ: completeTime,
              organizer_timezone: timezone_obj[0],
              time_in_selected_TZ: timeselected,
              custom_id: Object.values(params)[0],
              duration_options: hourMinutes,
              time: time,
              date: date,
              first_name: firstName,
              last_name: lastName,
              email: email,
              phone: phone,
              street: street,
              mobile: mobile,
              time_zone: timeZone,
              dynamic_calendar: calendar?.dynamic_calendar,
              scheduler_timezone: timezone_obj[0],
              token: TokenData[1],
              eventId: Tokens[1],
              captcha: calendar?.captcha,
              imageURL: calendar?.imageURL,
            };
            if (Tokens[1]) {
              await postRequest(
                navigate,
                meetingsEndpoint.rescheduleMeeting,
                payload
              );
              setLoader(false);
              setActiveStep(activeStep + 1);
              Mixpanel.track(STRINGS.CALENDAR_CONFIM_BTN);
            } else {
              await postRequest(
                navigate,
                meetingsEndpoint.CreateCalendarEvent,
                payload
              );
              Mixpanel.track(STRINGS.CALENDAR_CONFIM_BTN);

              setActiveStep(activeStep + 1);
            }
          } else {
            if (!token) {
              setValidations({
                captcha: "",
                email: validatedEmail.message,
              });
            } else {
              setValidations({
                email: validatedEmail.message,

                captcha: STRINGS.GOOGLE_CAPTCHA,
              });
            }
          }
        }
      } catch (error) {
        setLoader(false);

        EventEmitter.emit(EMITTER_ERROR, error?.data?.message);
      }
    } else {
      setInputError({
        firstName: firstValidation?.message,
        lastName: lastValidation?.message,
        mobile: mobileValidation?.message,
        phone: phoneValidation?.message,
        street: streetValidation?.message,
      });
    }
  };

  const handleGetAvailbleAPi = async (val, index) => {
    try {
      setColorClick(index);
      setLoader(true);
      setHourMinutes(val);
      const timezone_obj = TIMEZONE_LIST?.filter(
        (item) => item.id === timeZone[0]
      );

      const payload1 = {
        custom_id: Object.values(params)[0],
        date: date,
        complete_time_TZ: completeTime,
        duration_options: parseInt(val),
        scheduler_timezone: timezone_obj[0],
      };

      const response1 = await postRequest(
        navigate,
        meetingsEndpoint.GetEventmeeting,
        payload1
      );

      setResponse1(response1?.data?.data);

      setLoader(false);
    } catch (error) {
    } finally {
      setLoader(false);
    }
  };
  const options = response1;

  const Footer = (
    <Box className={classes.footerFlex}>
      <TypographyInline
        color={GREY.SECONDARY}
        fontWeight={fontWeight.REGULAR}
        size={"sm"}
        label={STRINGS.POWERERD_BY}
      />
      <Box className={classes.pointer} onClick={handleReachIQ}>
        <TypographyInline
          color={PRIMARY.W_500}
          fontWeight={fontWeight.REGULAR}
          label={STRINGS.REACHIQ}
          size={"sm"}
        />
      </Box>
    </Box>
  );

  useEffect(() => {
    (async () => {
      const host = window.location.host;
      let hostLength = host?.trim()?.split(".");
      if (hostLength.length > 1) {
        const subDomain = host?.trim()?.split(".")[0] + ".reachiq.ai";
        await verifyDomain(subDomain, navigate);
      }

      (async () => {
        try {
          const payload = params;

          const response = await postRequest(
            navigate,
            meetingsEndpoint.ScheduleMeeting,
            payload
          );

          setCalendar(response?.data?.data);
          setName(response?.data?.data?.avatar_image_url);

          setMainLoader(false);
          setAvailability(response?.data?.data?.availability);
          setHourMinutes(response?.data?.data?.duration_options[0]?.id);
          setCustomWeek(response?.data?.data?.custom_weeks);
          setRadioId(response?.data?.data?.meeting_can_be_scheduled);
          setFromDate(response?.data?.data?.custom_date_range?.from);
          setToDate(response?.data?.data?.custom_date_range?.to);
        } catch (error) {
          setMainLoader(false);
        } finally {
          setLoader(false);
        }
      })();
    })();
  }, []);
  useEffect(() => {
    const timezone_obj = TIMEZONE_LIST.filter(
      (item) => item.id === timeZone[0]
    );
    (async () => {
      const payload1 = {
        custom_id: Object.values(params)[0],
        date: date,
        duration_options: calendar?.duration_options[0]?.id,
        scheduler_timezone: timezone_obj[0],
      };
      setLoader(true);
      const response1 = await postRequest(
        navigate,
        meetingsEndpoint.GetEventmeeting,
        payload1
      );
      setResponse1(response1?.data?.data);
      setLoader(false);
    })();
  }, [calendar, timeZone]);
  useEffect(() => {
    (async () => {
      const host = window.location.host;
      let hostLength = host?.trim()?.split(".");
      if (hostLength.length > 1) {
        const subDomain = host?.trim()?.split(".")[0] + ".reachiq.ai";
        await verifyDomain(subDomain, navigate);
      }
      if (Tokens[1]) {
        (async () => {
          try {
            const payload = {
              custom_id: customIdUrl[3],
              eventId: Tokens[1],
            };

            const response = await postRequest(
              navigate,
              meetingsEndpoint.meetingStartRescheduling,
              payload
            );
            setFirstName(response?.data?.data?.first_name);
            setLastName(response?.data?.data?.last_name);
            setEmail(response?.data?.data?.email);
            setMobile(response?.data?.data?.mobile);
            setStreet(response?.data?.data?.street);
            setPhone(response?.data?.data?.phone);
            changeDate(response?.data?.data?.dateInRequiredTz);
            setValueStatestime([response?.data?.data?.time_zone?.label]);
            setTimeZone([response?.data?.data?.time_zone?.id]);
            setHourMinutes(response?.data?.data?.duration_options);
            setDurationSet(response?.data?.data?.booked_duration);
          } catch (error) {
            if (error.status === 409) {
              setError(true);
            }
          }
        })();
      }
    })();
  }, []);

  const isWeekend = (dateString) => {
    const date = new Date(dateString);
    const days = ["0", "1", "2", "3", "4", "5", "6"];
    const day = date.getDay();
    const value = days.filter((itm) => {
      return !Object.keys(availability).includes(itm);
    });
    const numberValues = value.map((itm) => parseInt(itm));
    return numberValues.includes(day);
  };

  const handleConnectMail = (id) => {
    if (id === 1) {
      handleGoogleAuthentication();
    } else {
      handleMicrosoftAuthentication();
    }
  };

  const workHandle = () => {
    window.location.href = REGISTRATION_LINK;
  };

  const renderAvatarComponent = () => {
    if (calendar?.avatar?.id === 0) {
      return <AvatarName size={"huge"} name={calendar?.organizer_name} />;
    } else if (calendar?.avatar?.id === 1) {
      return (
        <>
          {name ? (
            <AvatarOutlined size={"huge"}>
              <Image
                src={calendar?.avatar_image_url}
                className={classes.previewImage}
                alt="Selected Image"
              />
            </AvatarOutlined>
          ) : (
            <AvatarName size={"huge"} name={calendar?.organizer_name} />
          )}
        </>
      );
    } else if (calendar?.avatar?.id === 2) {
      return (
        <AvatarOutlined size={"huge"}>
          <Image
            className={classes.previewImage}
            src={calendar?.avatar_image_url}
            alt="add Image"
          />
        </AvatarOutlined>
      );
    }
  };
  const searchHandler = (e, property) => {
    let TimeoneValue = timezoneList;
    if (property === "timezoneList") {
      let data = TimeoneValue?.filter((item) =>
        item?.label?.toLowerCase()?.includes(e?.target?.value?.toLowerCase())
      );
      if (e?.target?.value === "" || undefined) {
        setTimezoneList(TIMEZONE_LIST);
      } else {
        setTimezoneList(data);
      }
    }
  };
  const step1 = (
    <>
      {" "}
      <Box className={classes.MediaStep2}>
        <Box
          sx={{ backgroundColor: calendar?.background_color }}
          className={classes.step2HeadContainer}
        >
          {renderAvatarComponent()}
          <Box>
            <TypographyInline
              color={BASE.WHITE}
              fontWeight={fontWeight.SEMIBOLD}
              size={"sm"}
              label={STRINGS.SCHEDULE_MEETING}
            />
            <TypographyInline
              color={BASE.WHITE}
              fontWeight={fontWeight.SEMIBOLD}
              size={"sm"}
              label={calendar?.organizer_name}
            />
          </Box>
        </Box>
      </Box>
      <Box className={classes.MediaStep2Container}>
        <Box item md={6} className={classes.GRIDCHILDD_BORDER}>
          <Box className={classes.HEIGHT}>
            <LocalizationProvider
              theme={Theme}
              dateAdapter={AdapterDayjs}
              sx={{ height: "100px" }}
            >
              <StaticDatePicker
                displayStaticWrapperAs="desktop"
                value={
                  date ||
                  (convertFormat(minDateCal) === convertFormat(new Date())
                    ? new Date()
                    : convertFormat(maxDateCal) < convertFormat(new Date())
                      ? null
                      : new Date())
                }
                className={classes.calendarPreview}
                shouldDisableDate={isWeekend}
                minDate={minDateCal}
                maxDate={maxDateCal}
                disablePast
                aimateYearScrolling={false}
                onChange={async (newValue) => {
                  changeDate(newValue);
                  const payload1 = {
                    custom_id: Object.values(params)[0],
                    date: newValue || date || new Date(),
                    duration_options: hourMinutes,
                    scheduler_timezone: TIMEZONE_LIST.filter(
                      (item) => item.id === timeZone[0]
                    )?.[0],
                  };
                  setLoader(true);
                  const response1 = await postRequest(
                    navigate,
                    meetingsEndpoint.GetEventmeeting,
                    payload1
                  );
                  setResponse1(response1?.data?.data);
                  setLoader(false);

                  if (response1?.status === 200) {
                    setLoader(false);
                  }

                  setLoader(false);
                }}
                InputProps={{
                  classesName: { notchedOutline: classes.noBorder },
                }}
                renderInput={(params) => <TextField {...params} />}
                dayOfWeekFormatter={(day) => `${day}`}
                toolbarFormat="ddd DD MMMM"
              />
            </LocalizationProvider>
          </Box>
        </Box>
        <Box className={classes.HowLongContainer} md={6}>
          <Box className={classes.HowLong}>
            <Box className={classes.SideBar}>
              <TypographyInline
                color={GREY.PRIMARY}
                fontWeight={fontWeight.MEDIUM}
                size={"sm"}
                label={STRINGS.HOW_LONG}
              />
              <div className={classes.durations}>
                {DurationSort?.map((value, i) => (
                  <Box
                    key={i}
                    className={
                      colorClick === i ? classes.minsRED : classes.mins
                    }
                    sx={{
                      color: colorClick === i ? data1?.background_color : "",
                      borderColor:
                        colorClick === i ? data1?.background_color : "",
                      display: "inline-flex",
                    }}
                    onClick={() => handleGetAvailbleAPi(value?.id, i)}
                  >
                    {value?.label}
                  </Box>
                ))}
              </div>
              <TypographyInline
                color={GREY.PRIMARY}
                fontWeight={fontWeight.MEDIUM}
                size={"sm"}
                label={STRINGS.WHAT_WORK_BEST}
              />
              <div className={classes.UTC}>
                <AutoCompleteCustom
                  valueState={valueStatestime}
                  setValueState={setValueStatestime}
                  idState={timeZone}
                  setIdState={setTimeZone}
                  size={"sm36"}
                  items={timezoneList}
                  LeftIcon=""
                  papermenuclass={classes.materialuiDrop}
                  isSearchEnable={true}
                  handleSearch={(event) => {
                    searchHandler(event, "timezoneList");
                  }}
                />
              </div>

              <Grid item className={classes.Overflow}>
                {loader ? (
                  <div style={styles.loaderContainer}>
                    {arraySkeleton?.map((item) => item?.label)}
                  </div>
                ) : options?.length > 0 ? (
                  options.map((obj, i) => (
                    <Box key={i}>
                      <Box
                        className={
                          colorClicked === i ? classes.mins : classes.mins
                        }
                        onClick={() => handleTime(obj, i)}
                      >
                        {obj?.time_in_selected_TZ}
                      </Box>
                    </Box>
                  ))
                ) : (
                  <p className={classes.works}>{STRINGS.NO_TIME_SLOT}</p>
                )}
              </Grid>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box className={classes.FOOTER1}>{Footer}</Box>
    </>
  );

  const step2 = (
    <>
      <Box className={classes.MediaStep2}>
        <Box
          sx={{ backgroundColor: calendar?.background_color }}
          className={classes.step2HeadContainer}
        >
          {renderAvatarComponent()}
          <Box>
            <TypographyInline
              color={BASE.WHITE}
              fontWeight={fontWeight.SEMIBOLD}
              size={"sm"}
              label={STRINGS.SCHEDULE_MEETING}
            />
            <TypographyInline
              color={BASE.WHITE}
              fontWeight={fontWeight.SEMIBOLD}
              size={"sm"}
              label={calendar?.organizer_name}
            />
          </Box>
        </Box>
      </Box>
      <Box className={classes.MediaStep2Container}>
        <Box item md={6} className={classes.GRIDCHILDD}>
          <Box className={classes.step2Leftcontainer}>
            <TypographyInline
              color={GREY.PRIMARY}
              fontWeight={fontWeight.SEMIBOLD}
              size={"sm"}
              label={STRINGS.YOUR_INFORMATION}
            />

            <Box className={classes.containEachInfo}>
              <Box className={classes.containIcon}>
                <IconComponent
                  iconLabel={ICON_LABELS.TODAY}
                  fontSize={fontSize.DXS}
                  color={GREY.QUATINARY}
                />
              </Box>
              <Box>
                <TypographyInline
                  color={GREY.SECONDARY}
                  fontWeight={fontWeight.REGULAR}
                  size={"xs"}
                  label={STRINGS.DATE}
                />
                <TypographyInline
                  color={GREY.PRIMARY}
                  fontWeight={fontWeight.MEDIUM}
                  size={"sm"}
                  label={convertFormat(date)}
                />
              </Box>
            </Box>

            <Box className={classes.containEachInfo}>
              <Box className={classes.containIcon}>
                <IconComponent
                  iconLabel={ICON_LABELS.HOUR_GLASS_BOTTOM}
                  fontSize={fontSize.DXS}
                  color={GREY.QUATINARY}
                />
              </Box>
              <Box>
                <TypographyInline
                  color={GREY.SECONDARY}
                  fontWeight={fontWeight.REGULAR}
                  size={"xs"}
                  label={STRINGS.DURATION}
                />
                <TypographyInline
                  color={GREY.PRIMARY}
                  fontWeight={fontWeight.MEDIUM}
                  size={"sm"}
                  label={hourMinutes + " " + STRINGS.MINS}
                />
              </Box>
            </Box>

            <Box className={classes.containEachInfo}>
              <Box className={classes.containIcon}>
                <IconComponent
                  iconLabel={ICON_LABELS.SCHEDULE}
                  fontSize={fontSize.DXS}
                  color={GREY.QUATINARY}
                />
              </Box>
              <Box>
                <TypographyInline
                  color={GREY.SECONDARY}
                  fontWeight={fontWeight.REGULAR}
                  size={"xs"}
                  label={STRINGS.TIME}
                />
                <TypographyInline
                  color={GREY.PRIMARY}
                  fontWeight={fontWeight.MEDIUM}
                  size={"sm"}
                  label={timeValue}
                />
              </Box>
            </Box>

            <Box className={classes.containEachInfo}>
              <Box className={classes.containIcon}>
                <IconComponent
                  iconLabel={ICON_LABELS.PUBLIC}
                  fontSize={fontSize.DXS}
                  color={GREY.QUATINARY}
                />
              </Box>
              <Box>
                <TypographyInline
                  color={GREY.SECONDARY}
                  fontWeight={fontWeight.REGULAR}
                  size={"xs"}
                  label={STRINGS.TIMEZONE}
                />
                <TypographyInline
                  color={GREY.PRIMARY}
                  fontWeight={fontWeight.MEDIUM}
                  size={"sm"}
                  label={timeZone}
                />
              </Box>
            </Box>

            <Box className={classes.Box}>
              <Button
                label={STRINGS.MAKE_IT}
                variant={"ghost"}
                onClick={() => setActiveStep(activeStep - 1)}
                size={"sm36"}
              />
            </Box>
          </Box>
        </Box>
        <Box item md={6}>
          <Box>
            <Box className={classes.SideBarRequire}>
              <TypographyInline
                color={GREY.PRIMARY}
                fontWeight={fontWeight.SEMIBOLD}
                size={"sm"}
                label={STRINGS.FILL_ALL_REQUIRED}
              />
              <Box className={classes.containInput}>
                <TypographyInline
                  color={GREY.QUATINARY}
                  fontWeight={fontWeight.REGULAR}
                  size={"sm"}
                  label={STRINGS.FIRST_NAME + " *"}
                />

                <InputField
                  size={"sm36"}
                  value={firstName}
                  placeholder={STRINGS.ENTER_FIRST_NAME}
                  onChange={(e) => setFirstName(e.target.value)}
                  variant={
                    inputError?.firstName ? STRINGS.ERROR : STRINGS.DEFAULT
                  }
                  errormessage={inputError?.firstName}
                />
              </Box>

              <Box className={classes.containInput}>
                <TypographyInline
                  color={GREY.QUATINARY}
                  fontWeight={fontWeight.REGULAR}
                  size={"sm"}
                  label={STRINGS.LAST_NAME + " *"}
                />

                <InputField
                  size={"sm36"}
                  value={lastName}
                  placeholder={STRINGS.ENTER_LAST_NAME}
                  onChange={(e) => setLastName(e.target.value)}
                  variant={
                    inputError?.lastName ? STRINGS.ERROR : STRINGS.DEFAULT
                  }
                  errormessage={inputError?.lastName}
                />
              </Box>

              <Box className={classes.containInput}>
                <TypographyInline
                  color={GREY.QUATINARY}
                  fontWeight={fontWeight.REGULAR}
                  size={"sm"}
                  label={STRINGS.EMAIL_ADDRESS + " *"}
                />

                <InputField
                  size={"sm36"}
                  type="email"
                  value={email}
                  placeholder={STRINGS.ENTER_EMAIL}
                  onChange={(e) => setEmail(e.target.value)}
                  variant={
                    validations?.email?.length ? STRINGS.ERROR : STRINGS.DEFAULT
                  }
                  errormessage={validations?.email}
                />
              </Box>

              {calendar?.dynamic_calendar && calendar?.dynamic_calendar?.[0] ? (
                <>
                  {calendar?.dynamic_calendar?.[0]?.name && (
                    <Box className={classes.containInput}>
                      <Box className={classes.FLEXED}>
                        <TypographyInline
                          color={GREY.QUATINARY}
                          fontWeight={fontWeight.REGULAR}
                          size={"sm"}
                          label={
                            calendar?.dynamic_calendar?.[0]?.required?.[0] ===
                              "true"
                              ? `${calendar?.dynamic_calendar?.[0]?.name} *`
                              : calendar?.dynamic_calendar?.[0]?.name
                          }
                        />
                      </Box>
                      <InputField
                        size={"sm36"}
                        required
                        type={"number"}
                        onChange={(e) => setPhone(e.target.value)}
                        value={phone}
                        placeholder={STRINGS.ENTER_PHONE_NUM}
                        variant={
                          inputError?.phone ? STRINGS.ERROR : STRINGS.DEFAULT
                        }
                        errormessage={inputError?.phone}
                      />
                    </Box>
                  )}
                </>
              ) : (
                ""
              )}

              {calendar?.dynamic_calendar && calendar?.dynamic_calendar?.[1] ? (
                <>
                  {calendar?.dynamic_calendar?.[1]?.name && (
                    <Box className={classes.containInput}>
                      <Box className={classes.FLEXED}>
                        <TypographyInline
                          color={GREY.QUATINARY}
                          fontWeight={fontWeight.REGULAR}
                          size={"sm"}
                          label={
                            calendar?.dynamic_calendar?.[1]?.required?.[0] ===
                              "true"
                              ? `${calendar?.dynamic_calendar?.[1]?.name} *`
                              : calendar?.dynamic_calendar?.[1]?.name
                          }
                        />
                      </Box>

                      <InputField
                        size={"sm36"}
                        required
                        onChange={(e) => setMobile(e.target.value)}
                        value={mobile}
                        type={"number"}
                        placeholder={STRINGS.ENTER_MOBILE_NUM}
                        variant={
                          inputError?.mobile ? STRINGS.ERROR : STRINGS.DEFAULT
                        }
                        errormessage={inputError?.mobile}
                      />
                    </Box>
                  )}
                </>
              ) : (
                ""
              )}
              {calendar?.dynamic_calendar && calendar?.dynamic_calendar?.[2] ? (
                <>
                  {calendar?.dynamic_calendar?.[2]?.name && (
                    <Box className={classes.containInput}>
                      <Box className={classes.FLEXED}>
                        <TypographyInline
                          color={GREY.QUATINARY}
                          fontWeight={fontWeight.REGULAR}
                          size={"sm"}
                          label={
                            calendar?.dynamic_calendar?.[2]?.required?.[0] ===
                              "true"
                              ? `${calendar?.dynamic_calendar?.[2]?.name} *`
                              : calendar?.dynamic_calendar?.[2]?.name
                          }
                        />
                      </Box>
                      <InputField
                        size={"sm36"}
                        required
                        placeholder={STRINGS.ENTER_STREET_ADDRESS}
                        onChange={(e) => setStreet(e.target.value)}
                        value={street}
                        variant={
                          inputError?.street ? STRINGS.ERROR : STRINGS.DEFAULT
                        }
                        errormessage={inputError?.street}
                      />
                    </Box>
                  )}
                </>
              ) : (
                ""
              )}

              {calendar?.captcha ? (
                <Box className={classes.containInput}>
                  <ReCAPTCHA
                    ref={captchaRef}
                    sitekey={process.env.REACT_APP_SITE_KEY}
                    onChange={(token) => setToken(token)}
                  />

                  {validations.captcha && (
                    <ErrorMessage message={validations.captcha} />
                  )}
                </Box>
              ) : (
                ""
              )}

              <Box className={classes.Confirmed}>
                <Box className={classes.fitWidth}>
                  <Button
                    backgroundColor={data1?.background_color}
                    label={STRINGS.CONFIRM}
                    size={"sm36"}
                    variant={
                      firstName === "" && lastName === ""
                        ? "ghost"
                        : "primary_nohover"
                    }
                    disabled={firstName === "" && lastName === ""}
                    onClick={() => handleCalendarEventApi()}
                    isLoading={loader}
                  />
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box className={classes.FOOTER1}>{Footer}</Box>
    </>
  );

  const step3 = (
    <Box className={classes.Steps3}>
      {/* Meeting Header */}
      <Box
        sx={{ backgroundColor: calendar?.background_color }}
        className={classes.step2HeadContainer}
      >
        {renderAvatarComponent()}
        <Box>
          <TypographyInline
            color={BASE.WHITE}
            fontWeight={fontWeight.SEMIBOLD}
            size={"sm"}
            label={STRINGS.SCHEDULE_MEETING}
          />
          <TypographyInline
            color={BASE.WHITE}
            fontWeight={fontWeight.SEMIBOLD}
            size={"sm"}
            label={calendar?.internal_name}
          />
        </Box>
        {/* Meeting Header */}
      </Box>
      <Box className={classes.containFlexInfo}>
        <Box className={classes.containImage}>
          <Image src={CALENDAR} className={classes.toggle} />
        </Box>
        <Box>
          <Box>
            <TypographyInline
              color={GREY.PRIMARY}
              fontWeight={fontWeight.SEMIBOLD}
              size={"md"}
              label={STRINGS.MEETING_DETAILS}
            />
          </Box>
          <Box className={classes.containAllInfo}>
            <div>
              <Box className={classes.containEachInfo}>
                <Box className={classes.containIcon}>
                  <IconComponent
                    iconLabel={ICON_LABELS.TODAY}
                    fontSize={fontSize.DXS}
                    color={GREY.QUATINARY}
                  />
                </Box>
                <Box>
                  <TypographyInline
                    color={GREY.SECONDARY}
                    fontWeight={fontWeight.REGULAR}
                    size={"xs"}
                    label={STRINGS.DATE}
                  />
                  <TypographyInline
                    color={GREY.PRIMARY}
                    fontWeight={fontWeight.MEDIUM}
                    size={"sm"}
                    label={convertFormat(date)}
                  />
                </Box>
              </Box>
              <Box className={classes.containEachInfo}>
                <Box className={classes.containIcon}>
                  <IconComponent
                    iconLabel={ICON_LABELS.HOUR_GLASS_BOTTOM}
                    fontSize={fontSize.DXS}
                    color={GREY.QUATINARY}
                  />
                </Box>
                <Box>
                  <TypographyInline
                    color={GREY.SECONDARY}
                    fontWeight={fontWeight.REGULAR}
                    size={"xs"}
                    label={STRINGS.DURATION}
                  />
                  <TypographyInline
                    color={GREY.PRIMARY}
                    fontWeight={fontWeight.MEDIUM}
                    size={"sm"}
                    label={hourMinutes + " " + STRINGS.MINS}
                  />
                </Box>
              </Box>
            </div>
            <div>
              <Box className={classes.containEachInfo}>
                <Box className={classes.containIcon}>
                  <IconComponent
                    iconLabel={ICON_LABELS.SCHEDULE}
                    fontSize={fontSize.DXS}
                    color={GREY.QUATINARY}
                  />
                </Box>
                <Box>
                  <TypographyInline
                    color={GREY.SECONDARY}
                    fontWeight={fontWeight.REGULAR}
                    size={"xs"}
                    label={STRINGS.TIME}
                  />
                  <TypographyInline
                    color={GREY.PRIMARY}
                    fontWeight={fontWeight.MEDIUM}
                    size={"sm"}
                    label={timeValue}
                  />
                </Box>
              </Box>
              <Box className={classes.containEachInfo}>
                <Box className={classes.containIcon}>
                  <IconComponent
                    iconLabel={ICON_LABELS.PUBLIC}
                    fontSize={fontSize.DXS}
                    color={GREY.QUATINARY}
                  />
                </Box>
                <Box>
                  <TypographyInline
                    color={GREY.SECONDARY}
                    fontWeight={fontWeight.REGULAR}
                    size={"xs"}
                    label={STRINGS.TIMEZONE}
                  />
                  <TypographyInline
                    color={GREY.PRIMARY}
                    fontWeight={fontWeight.MEDIUM}
                    size={"sm"}
                    label={timeZone}
                  />
                </Box>
              </Box>
            </div>
          </Box>
        </Box>
      </Box>
      <Box className={classes.padSign}>
        <TypographyInline
          color={GREY.PRIMARY}
          fontWeight={fontWeight.SEMIBOLD}
          size={"xl"}
          label={STRINGS.SCHEDULE_FREE}
        />
      </Box>
      <Box className={classes.signUpFlex}>
        {espConnectionList?.map((val) => {
          return (
            <button
              className={classes.socialAuthBtn}
              onClick={() => handleConnectMail(val?.id)}
            >
              <Image src={val.icon} className={classes.socialAuthIcon} />
              {val.label}
            </button>
          );
        })}
      </Box>
      <Box className={classes.padSign1}>
        <TypographyInline
          color={PRIMARY.W_500}
          fontWeight={fontWeight.MEDIUM}
          size={"sm"}
          label={STRINGS.SIGN_WORK}
          onClick={workHandle}
        />
      </Box>
      <Box className={classes.FOOTER}> {Footer}</Box>
    </Box>
  );

  const steps = [
    {
      label: STRINGS.OVERVIEW,
      component: step1,
      requiredFields: [],
    },
    {
      label: STRINGS.SCHEDULING,
      component: step2,
      requiredFields: [firstName, lastName, email],
    },
    {
      label: STRINGS.SCHEDULING,
      component: step3,
      requiredFields: [],
    },
  ];
  console.log({ error });
  return mainLoader ? (
    <FullScreenLoader />
  ) : error ? (
    <Box className={classes.SubHeadTop}>
      <Box className={classes.SubHeadConatinerErr}>
        <TypographyInline
          size={"sm"}
          color={GREY.PRIMARY}
          fontWeight={fontWeight.BOLD}
          label={"This meeting no longer exists."}
        />
      </Box>
    </Box>
  ) : calendar?.organizer_name ? (
    <Box activeStep={activeStep}>
      <Box className={classes.childrenContainer} id="modal_body">
        <Box className={classes.SubChild}>
          {!calendar?.imageURL ? (
            <Box>
              <div className={classes.LOGO_CAL}>
                <img src={NEW_LOGIN_REACHIQ_LOGO} alt="reachiq" />
              </div>
            </Box>
          ) : (
            <Box className={classes.JUSTIFIED}>
              <img
                src={calendar?.imageURL}
                alt={calendar?.imageURL}
                className={classes.FOOTERIMAGE}
              />
            </Box>
          )}
          <Grid container spacing={0} sx={{ height: "calc(100vh - 30vh)" }}>
            <Grid
              item
              className={classes.claendarWrap}
              sx={{
                mx: "auto",
                mt: "1.823vw",
                minHeight: loading ? "387.5px" : "auto",
              }}
            >
              <Box className={classes.eachStepContainer}>
                {steps?.[activeStep]?.component}
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
  ) : (
    <InvalidRouteScreen />
  );
};

export default CalendarScreen;
