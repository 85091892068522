import React from 'react'
import { styles } from './styles';
import { STRINGS } from './strings';

import { BASE, ERROR, GREY } from '../../utils/constants/colors';
import { ICON_LABELS, fontSize, fontWeight } from '../../utils/constants/UI';
import Typography from '../../components/Typography/Typography';
import { Button } from '../../components/Buttons';
import IconComponent from '../../components/Icon/IconComponent';
import { FLAGGED_SCREEN_MESSAGE } from '../../utils/constants/messages';
import TypographyInline from '../../components/Typography/TypographyInline';

const accountFlagged = () => {
  const classes = styles();
  const handleNavigation = () => {

    window.open("https://www.reachiq.ai/contact/", "_blank")
  };
  return (
    <div className={classes.accountflagged}>
      <div className={classes.accountflaggedbox}>
        <div className={classes.iconflag}>
          <IconComponent
            color={ERROR.W_500}
            fontSize={fontSize.DXL}
            iconLabel={ICON_LABELS.FLAG_ICON}
          />
        </div>
        <TypographyInline
          size={"xl"}
          color={BASE.WHITE}
          fontWeight={fontWeight.SEMIBOLD}
          label={"Account Flagged"}
        />
        <div className={classes.spacetop}>
          <TypographyInline
            size={"xs"}
            color={BASE.WHITE}
            fontWeight={fontWeight.REGULAR}
            label={FLAGGED_SCREEN_MESSAGE}
          />
        </div>
        <div className={classes.btnsupport}>
          <Button
            label={"Contact Support"}
            size={"sm36"}
            variant={"ghost"}
            LeftIcon={ICON_LABELS.CALL}
            onClick={handleNavigation}

          />

        </div>
      </div>
    </div>
  )
}

export default accountFlagged;
