import { makeStyles } from "@mui/styles";
import {
  SCROLL_BAR,
  SHADOW,
  borderRadius,
} from "../../../utils/constants/UI/uiConstants";
import { BASE, BORDER, GREY } from "../../../utils/constants/colors";

export const styles = makeStyles({
  SubHeadTop: {
    paddingTop: "50px",
    height: "100vh",
    display: "flex",
    justifyContent: "center",
    background: "#F5F5F5",
  },
  SubHeadConatinerErr: {
    padding: "32px",
    height: "150px",
    width: "500px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    background: BASE.WHITE,
    borderRadius: "6px",
  },
  containAllInfo: {
    paddingBottom: "32px",
    display: "flex",
    gap: "48px",

    "@media  (min-width: 320px) and (max-width: 480px)": {
      // gap: "48px",
      justifyContent: "center",
      flexWrap: "wrap",
    },
  },
  HowLong: {
    // testing
    // height: "442px",

    "@media  (min-width: 320px) and (max-width: 480px)": {
      width: "100%",
    },
  },
  HowLongContainer: {
    width: "100%"
  },
  Steps3: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  containImage: {
    padding: "1.667vw 0",
  },
  containFlexInfo: {
    display: "flex",
    alignItems: "center",
    gap: "3.33vw",
    borderBottom: "1px solid #E6E6E6",
    padding: ".833vw",
  },
  padSign: {
    paddingTop: ".833vw",
  },
  padSign1: {
    paddingTop: ".833vw",
    cursor: "pointer",
  },
  signUpFlex: {
    display: "flex",
    gap: "10px",
    paddingTop: ".833vw",
  },
  socialAuthBtn: {
    background: "white",
    boxShadow:
      "0px 3px 4px rgba(24, 24, 28, 0.04), 0px 1px 1px rgba(24, 24, 28, 0.04)",
    border: "1px solid #E6E6E6",
    display: "flex",
    alignItems: "center",
    fontWeight: "400",
    fontSize: "14px",
    borderRadius: "6px",
    width: "295px",
    height: "40px",
    padding: "15px",
    whiteSpace: "nowrap",
    justifyContent: "center",
    color: "#222124",
    cursor: "pointer",
  },
  socialAuthIcon: {
    marginRight: "10px",
    objectFit: "contain",
    width: "clamp(1.4rem,1.7vw,3.5rem)",
    height: "clamp(1.4rem,1vh,2.5rem)",
  },
  containEachInfo: {
    display: "flex",
    paddingTop: "1.5rem",
  },
  containIcon: {
    display: "flex",
    alignItems: "center",
    paddingRight: "12px",
  },
  MediaStep2Container: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    "@media  (min-width: 320px) and (max-width: 480px)": {
      width: "100%",
      alignItems: "center",
      justifyContent: "center",
      display: "flex",
      flexDirection: "column",
    },
  },
  containStep: {
    width: "45.833vw",
  },
  eachStepContainer: {
    width: "45.833vw",

    "@media  (min-width: 320px) and (max-width: 1024px)": {
      width: "93.2vw",
    },
  },

  step2HeadContainer: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    gap: "8px",
    padding: "1rem",
    borderRadius: `${borderRadius.secondary} ${borderRadius.secondary} 0 0`,
  },
  containInput: {
    paddingTop: "1rem",
    display: "flex",
    flexDirection: "column",
    gap: "4px",
  },
  fitWidth: {
    width: "fit-content",
  },
  step2Leftcontainer: {
    padding: "28px 36px 28px 36px ",
    "@media  (min-width: 320px) and (max-width: 480px)": {
      width: "82vw",
    },
  },

  Padding_Left: {
    paddingLeft: "1rem",
  },
  FORM: {
    width: "100%",
    height: "366px",
    background: "white",
    marginTop: "30px",
    display: "flex",
    "@media (max-width: 1470px)": {
      marginTop: "8px",
      height: "40vh",
    },
  },

  Date: {
    display: "flex",
  },
  DateTop: { display: "flex", marginBottom: "1rem", marginTop: "1rem" },
  Your: {
    marginLeft: "35px",
    borderBottom: "0.708129px solid #D3DAE3",
    // background: "red",
    color: "#212833",
    padding: "clamp(8px,0.55vw, 12px)",
    display: "flex",
    justifyContent: "center",
    fontSize: "clamp(12px,0.729vw,16px)",
    fontWeight: "600",
  },
  YourInfo: {
    width: "40vw",
    height: "366px",

    borderRight: "0.708129px solid #D3DAE3",
    "@media (max-width: 1470px)": {
      height: "40vh",
      width: "16vw",
    },
    // "@media (min-width: 1470px) and (max-width:1700px)":{
    //   height: "420px",
    // }
    // "@media (max-width: 1920px) (min-width:1460px)": {
    //   height: "420px",

    // }
  },
  IMAGE: {
    width: "74px",
    height: "74px",
    marginTop: "22px",
    "@media (max-width: 1470px)": {
      width: "50px",
      height: "50px",
      marginTop: "8px",
    },
  },
  need: {
    fontWeight: "600",
    fontSize: "clamp(12px,0.729vw,16px)",
    color: " #212833",
    // paddingTop: "1rem",
    // paddingBottom: "1rem",
  },
  UTC: {
    fontWeight: "600",
    fontSize: "clamp(10px,0.625vw,12px)",
    color: GREY.TERTIARY,
    paddingTop: "8px",
    paddingBottom: "8px",
    paddingRight: "1rem",
  },
  FLEXED: {
    display: "flex",
  },
  JUSTIFIED: {
    display: "flex",
    justifyContent: "center",
  },
  HEIGHT: {
    // testing
    // height: "442px"
  },
  works: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",

    position: "relative",
    paddingTop: "20px",
    fontWeight: "600",
    fontSize: "clamp(12px,0.729vw,16px)",
    color: " #212833",
  },
  childrenContainer: {
    background: "#f7f9fb",
    // width: "calc(100% - 5.6rem)",
    // background: `url(${MAKE_DEFAULT_WHITE_ICON}) right top no-repeat ,url(${MAKE_DEFAULT_WHITE_ICON}) left bottom no-repeat `,
    // backgroundImage: `url(${MAKE_DEFAULT_WHITE_ICON})`,
    height: "100vh",
    display: "flex",
    alignItems: "center",
    overflow: "auto",
    ...SCROLL_BAR,
    "@media  (min-width: 320px) and (max-width: 480px)": {
      overflow: "auto",
      ...SCROLL_BAR,
    },
  },
  mins: {
    display: "flex",
    margin: "0 0 8px 0",
    fontWeight: "400",
    padding: "8px 10px",
    justifyContent: "center",
    // width: "80px",
    fontSize: "14px",
    color: "#5F5F61",
    borderRadius: "6px",
    border: " 1px solid #E6E6E6",
    // margin: "5px 6px 1px 0",
    background: "#FFFFFF",
    // margin: "0.781vw 6px 1.302vw 0",
    cursor: "pointer",
    "&:hover": {
      color: (props) => props.background,

      borderColor: (props) => props.background,
    },
  },
  minsRED: {
    cursor: "pointer",
    fontWeight: "400",
    borderRadius: "6px",
    border: " 1px solid #E6E6E6",
    display: "inline-flex",
    justifyContent: "center",
    fontSize: "14px",
    color: (props) => props.background,
    borderColor: (props) => props.background,
    margin: "0 0 8px 0",

    // margin: "0.781vw 6px 1.302vw 0",
    padding: "8px 10px",
  },
  toggle: {
    width: "206px",
    height: "168.15px",
  },
  minutes: {
    fontWeight: "400",
    padding: "0.6rem",
    display: "flex",
    justifyContent: "center",
    // width: "80px",
    fontSize: "10px",
    color: "#626B7E",
    borderRadius: "16.9951px",
    border: " 0.708129px solid red",
    "@media (max-width: 1470px)": {
      padding: "0.3rem",
      margin: "4px",
    },
  },
  // textName: {
  //   fontWeight: "700",
  //   fontSize: "clamp(1rem,1.042vw,20px)",
  //   color: "black",
  // },
  materialui: {
    fontFamily: "inherit",
    width: "100%",
    height: "36px !important",
    background: BASE.WHITE,
    borderRadius: "5px",
    color: "#212833",
    border: "1px solid #E0E0E0",
    padding: "0 0 0 0.8rem",
  },
  StaticDatePickers: {
    minHeight: "280px !important",
    "& .css-sf5t6v-PrivatePickersSlideTransition-root-MuiDayPicker-slideTransition":
    {
      minHeight: "280px !important",
    },
    " &   .css-nk89i7-MuiPickersCalendarHeader-root": {
      backgroundColor: "#ff4848 !important",
      // opacity: "0.1",
      marginTop: "0px",
    },
    "& .css-bkrceb-MuiButtonBase-root-MuiPickersDay-root.Mui-selected": {
      backgroundColor: "#FF4848 !important",
      boxShadow: "0px 7px 14px rgba(255, 72, 72, 0.33) !important",
    },
    " &   MuiPickersCalendarHeader-label": {
      color: "white !important",
    },
    " &  .css-195y93z-MuiButtonBase-root-MuiPickersDay-root.Mui-selected": {
      backgroundColor: "#FF4848 !important",
      boxShadow: "0px 7px 14px rgba(255, 72, 72, 0.33) !important",
    },
    " & .css-nk89i7-MuiPickersCalendarHeader-root": {
      backgroundColor: "#FF4848 !important",
      color: "white",
      // opacity: "0.3",
      padding: "1.5rem",
      marginTop: "0px",
    },
    "& .css-169iwlq-MuiCalendarPicker-root ": {
      margin: "0 !important",
      width: "auto !important",
    },
    "& .css-epd502": {
      margin: "0 !important",
      width: "auto !important",
    },
    " & .css-1ae9t7h-MuiButtonBase-root-MuiIconButton-root-MuiPickersArrowSwitcher-button.Mui-disabled":
    {
      color: "white",
    },

    "&  .css-jro82b-MuiButtonBase-root-MuiIconButton-root-MuiPickersArrowSwitcher-button.Mui-disabled":
    {
      color: "white",
    },
    "& .css-1tkx1wf-MuiSvgIcon-root-MuiPickersCalendarHeader-switchViewIcon": {
      display: "none",
    },
  },

  SideBar: {
    // testing
    // padding:"36px 28px 36px 28px",
    padding: "28px",

    // padding: "1.146vw 1.458vw",
    "@media  (min-width: 320px) and (max-width: 1300px)": {
      paddingLeft: "20px",
      paddingTop: "20px",
    },
  },
  // SideBar: {
  //   padding: "1.146vw 1.458vw",
  //   "@media  (min-width: 320px) and (max-width: 480px)": {
  //     paddingTop: "2rem",
  //   },
  // },
  SideBarRequire: {
    overflowY: "auto",
    // maxHeight: "44vh",
    borderLeft: "1px solid #EFEFEF",
    // borderRight: "1px solid #D3DAE3",
    maxHeight: "60vh",
    overflow: "auto",
    ...SCROLL_BAR,
    padding: "28px 36px",
    "@media  (min-width: 320px) and (max-width: 480px)": {
      padding: "1rem",
      maxHeight: "100%",
      borderLeft: "0px solid #D3DAE3",
      borderRight: "0px solid #D3DAE3",
    },
  },
  textBox: {
    borderRadius: "4px",
    padding: "0.5rem",

    width: "100%",
    height: "40px",
    border: "1px solid #D3DAE3",
    "@media (max-width: 1470px)": {
      width: "100%",
    },
  },
  textBox1: {
    width: "100%",
    border: "1px solid #D3DAE3",

    "@media (max-width: 1470px)": {
      width: "100%",
    },
  },
  textSchedule: {
    fontWeight: "300",
    fontSize: "clamp(12px,0.833vw,14px)",
    color: "#626B7E",
    marginTop: "7px",
  },
  Overflow: {
    // height: "243px",
    // testing
    height: "30vh",

    paddingRight: "10px",
    overflow: "auto",
    ...SCROLL_BAR,
    display: "block",
    margin: "auto",
    "@media (min-width: 1470px)": {
      // height: "160px",
      // height: "246px",
    },
  },
  textName: {
    fontWeight: "700",
    fontSize: "clamp(16px,1.042vw,20px)",
    color: "#FF4848",
    marginTop: "6px",
  },
  LOGO_CAL: {
    display: "flex",
    justifyContent: "center",
    "@media  (min-width: 320px) and (max-width: 480px)": {
      padding: "1rem",
    },
  },
  GRID_PARENT: {
    display: "flex",
    justifyContent: "center",
  },

  GRIDCHILD1: {
    boxShadow: "0px 24px 34px rgba(0, 0, 0, 0.04)",
    background: "white",
    borderRadius: "12px 0px 0px 12px",
  },
  image: {
    width: "146px",
    height: "146px",
    border: "3px solid #FF4848",
    padding: "4px",
    borderRadius: "70px",
    "@media  (min-width: 320px) and (max-width: 480px)": {
      width: "81px",
      height: "81px",
    },
  },
  imagePreview: {
    width: "60px",
    height: "60px",
    border: "3px solid #FF4848",
    padding: "4px",
    borderRadius: "70px",
    "@media  (min-width: 320px) and (max-width: 480px)": {
      width: "50px",
      height: "50px",
    },
  },
  MediaSteps: {
    "@media  (min-width: 320px) and (max-width: 480px)": {
      alignItems: "center",
      justifyContent: "center",
      display: "flex",
      paddingBottom: "1rem",
    },
  },
  BOX1: {
    // paddingRight: "35px",
    display: "flex",
    justifyContent: "center",
    paddingTop: "6rem",
    "@media  (min-width: 320px) and (max-width: 480px)": {
      paddingTop: "0rem",
    },
  },
  centering: {
    display: "flex",
    gap: "10px",
    justifyContent: "center",
    alignItems: "center",
    "@media  (min-width: 320px) and (max-width: 480px)": {
      justifyContent: "center",
    },
  },
  BOXPewviews: {
    display: "flex",
    justifyContent: "center",
    paddingTop: "0rem",
    "@media  (min-width: 320px) and (max-width: 480px)": {
      paddingTop: "0rem",
    },
  },
  BOX2: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
    "@media  (min-width: 320px) and (max-width: 480px)": {
      paddingBottom: "0rem",
    },
  },
  BOXES: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
    "@media  (min-width: 320px) and (max-width: 480px)": {
      paddingBottom: "0rem",
    },
  },
  BOX3: {
    borderLeft: "1px solid #D3DAE3",
    borderRight: "1px solid #D3DAE3",
    "@media  (min-width: 320px) and (max-width: 480px)": {
      borderBottom: "1px solid #D3DAE3",
    },
    //   display: "flex",
    //   justifyContent: "center",
    //   alignItems: "center",
    // },
  },
  SubChild: {
    width: "100%",
    // ...SCROLL_BAR,
    // overflow: "auto",
    // height: "100vh",
    // ...SCROLL_BAR,
    // overflow:"auto",
    // "@media  (min-width: 320px) and (max-width: 480px)": {
    //   padding: "1rem",
    //   height: "100%",
    // },
  },
  date: {
    fontWeight: "400",
    fontSize: "clamp(11px,0.625vw,13px)",
    color: "#626B7E",
  },

  Text: {
    fontWeight: "400",
    fontSize: "clamp(11px,0.625vw,13px)",
    paddingBottom: "0.5rem",

    color: "#212833",
  },
  Required: {
    fontWeight: "600",
    fontSize: "14px",
    paddingBottom: "0.8rem",

    color: "#212833",
  },
  previewInput: {
    background: "white",
    marginBottom: "0.4rem",
    width: "100%",
    height: "clamp(30px,2.083vw, 50px)",
    border: "0.777512px solid #D3DAE3",
    borderRadius: "4.6px",
    padding: "0.5rem",
    "@media (max-width: 1470px)": {
      width: "100%",
    },
  },
  MeetingBooked: {
    "@media  (min-width: 320px) and (max-width: 480px)": {
      maxWidth: "100% !important",
    },
  },

  Mailsend: {
    // "@media  (min-width: 320px) and (max-width: 480px)": {
    //   display: "block !important",
    // },
  },
  MeetingBook: {
    fontWeight: "300",
    fontSize: "12px",

    color: "#626B7E",
  },
  MeetingOwner: {
    fontWeight: "700",
    fontSize: "18px",
    paddingBottom: "0.9rem",
    borderBottom: "1px solid #D3DAE3",

    color: "#212833",
  },
  Confirmed: {
    cursor: "pointer",
    display: "flex",
    justifyContent: "flex-start",
    padding: "1rem",
    paddingLeft: "0rem",
    "@media (max-width: 1470px)": {
      paddingTop: "0.5rem",
      paddingLeft: "0rem",
    },
    // "@media (max-width: 1920px)": {
    //   paddingTop: "3rem",
    //   // paddingLeft:"0rem"
    // }
  },
  confirm: {
    color: "white",
    fontSize: "12px",
    fontWeight: "400",
    paddingLeft: "2rem",
    paddingTop: "0.5rem",
    paddingRight: "2rem",
    borderRadius: "8px",
    paddingBottom: "0.5rem",
    border: (props) => props.background || "1px solid red",
    cursor: "pointer",
    background: (props) => props.background || "red",
    "&:hover": {
      background: (props) => props.background || "#0F2F51 !important",
      border: (props) => props.background || "1px solid #0F2F51 !important",
    },
    "@media (max-width: 1470px)": {
      fontSize: "10px",
      paddingRight: "1.2rem",
      paddingLeft: "1.2rem",
    },
  },
  confirmDisable: {
    color: "#626B7E",
    fontSize: "12px",
    fontWeight: "400",
    paddingLeft: "2rem",
    paddingTop: "0.5rem",
    paddingRight: "2rem",
    borderRadius: "8px",
    paddingBottom: "0.5rem",
    border: "1px solid #D3DAE3",
    background: "#D3DAE3",
    cursor: "pointer",
    "@media (max-width: 1470px)": {
      fontSize: "10px",
      paddingRight: "1.2rem",
      paddingLeft: "1.2rem",
    },
  },
  padding: {
    padding: "2rem 1rem 1rem 2rem",
    "@media  (min-width: 320px) and (max-width: 480px)": {
      padding: "1rem",
    },
  },
  Box: {
    display: "flex",
    justifyContent: "center",
    paddingTop: "1.5rem",
    width: "fit-content",
    "@media  (min-width: 320px) and (max-width: 480px)": {
      paddingLeft: "1rem",
      justifyContent: "start",
    },
  },
  edit: {
    cursor: "pointer",
    background: "white",
    border: "1px solid red",
    borderRadius: "8px",

    // color: "#FF4848",

    padding: "0.5rem 2rem 0.5rem 2rem",

    fontWeight: "400",
    fontSize: "12px",

    color: "#FF4848",
  },
  Img: {
    marginRight: "22px",
    marginTop: "10px",
    width: "16px",
    height: "15px",
  },
  AccDate: {
    fontWeight: "500",
    fontSize: "clamp(12px,0.729vw,16px)",
    color: "#212833",
  },
  Select: {
    fontWeight: "600",
    display: "flex",
    justifyContent: "center",
    fontSize: "14px",
    color: "white",
    borderTop: "0.708129px solid #D3DAE3",
    // borderBottom:" 1px solid #0F2F51",

    background: "#FF4848",
    padding: "0.9rem",
  },
  GRIDCHILD2: {
    boxShadow: "0px 24px 34px rgba(0, 0, 0, 0.04)",
    background: "white",
    borderRadius: "0px 12px 12px 0px",
  },
  GRIDCHILDD: {
    // boxShadow: "0px 24px 34px rgba(0, 0, 0, 0.04)",
    background: "white",

    // borderRight: "0.708129px solid #D3DAE3",
    // borderLeft: "0.708129px solid #D3DAE3",
    "@media  (min-width: 320px) and (max-width: 480px)": {
      borderBottom: "0.708129px solid #D3DAE3",
    },
  },
  GRIDCHILDD_BORDER: {
    borderRight: "1px solid #EFEFEF",
    background: "white",
    "@media  (min-width: 320px) and (max-width: 480px)": {
      borderBottom: "0.708129px solid #D3DAE3",
      width: "100%"
    },
  },
  GRIDCHILD3: {
    background: "white",
  },
  Media: {
    "@media  (min-width: 320px) and (max-width: 480px)": {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100%",
      // overflow: "auto",
      padding: "20px 0px",
    },
  },
  MediaStep2: {
    "@media  (min-width: 320px) and (max-width: 480px)": {
      display: "block !important",
      justifyContent: "center",
      alignItems: "center",
      height: "100%",
      // overflow: "auto",
      // padding: "20px 0px",
    },
  },
  Media2: {
    "@media  (min-width: 320px) and (max-width: 480px)": {
      width: "100%",
      padding: "1rem",
    },
  },
  FOOTERIMAGE: {
    objectFit: "contain",
    width: "200px",
    // height: "60px",
    display: "flex",
    justifyContent: "center",
    paddingTop: "1rem",
    fontWeight: "400",
    fontSize: "clamp(10px,0.625vw,14px)",
    color: "#212833",
    "@media  (min-width: 320px) and (max-width: 480px)": {
      paddingBottom: "1rem",
    },
  },
  footerFlex: {
    display: "flex",
    gap: "0.2rem",
  },
  pointer: {
    cursor: "pointer",
  },
  FOOTER: {
    width: "100%",
    borderTop: `1px solid ${BORDER.LIGHT}`,
    display: "flex",
    justifyContent: "center",
    padding: "1rem",
    marginTop: "1rem",
    fontWeight: "400",
    fontSize: "clamp(10px,0.625vw,14px)",
    color: "#212833",
    "@media  (min-width: 320px) and (max-width: 480px)": {
      paddingBottom: "1rem",
    },
  },
  FOOTER1: {
    width: "100%",
    borderTop: `1px solid ${BORDER.LIGHT}`,

    display: "flex",
    justifyContent: "center",
    padding: "1rem",
    fontWeight: "400",
    fontSize: "clamp(10px,0.625vw,14px)",
    color: "#212833",
    "@media  (min-width: 320px) and (max-width: 480px)": {
      paddingBottom: "1rem",
    },
  },
  calendarPreview: {
    "& .css-sf5t6v, .PrivatePickersSlideTransition-root,.MuiDayPicker-slideTransition":
    {
      // comment for testing
      // minHeight: "280px !important",
    },
    background: "transparent",
    "& .css-epd502, .MuiPickerStaticWrapper-content,.MuiCalendarPicker-root": {
      width: "100%",
      height: "100%",
      margin: "0",
    },
    "& .MuiDayPicker-header,.MuiDayPicker-weekContainer": {
      justifyContent: "space-evenly",
      margin: "0px",
    },
    "& .MuiDayPicker-weekDayLabel": {
      fontFamily: "'Inter', sans-serif",
      color: "#212833",
      fontSize: "clamp(13px,0.787vw, 17px)",
      fontWeight: "400",
    },
    "& .MuiPickersDay-dayWithMargin": {
      fontFamily: "'Inter', sans-serif",
      color: "#222124",
      fontSize: "clamp(13px,0.787vw, 17px)",
      margin: "5px 2px;",
      fontWeight: "400",
    },
    "& .Mui-selected[aria-selected='true']": {
      background: (props) => props.background || "#FF4848",
      // border: "0px",
      color: "#fff",
      border: "0!important",
      boxShadow: SHADOW.md,
      flex: "none",
      "&:hover": {
        background: (props) => props.background,
      },
    },
    "& .MuiButtonBase-root:not(.Mui-selected)": {
      border: "0!important",
    },
    "& .MuiPickersCalendarHeader-root": {
      background: BASE.WHITE,
      borderTop: "1px solid rgba(15, 47, 81, 0.15)",
      marginTop: "0px",
      color: GREY.PRIMARY,
      // padding: "10px 10px 10px 1.302vw",
      // testing
      padding: "20px ",

      maxHeight: "initial",
    },
    "& .MuiSvgIcon-fontSizeMedium,.MuiButtonBase-root-MuiIconButton-root-MuiPickersArrowSwitcher-button,.MuiIconButton-root-MuiPickersArrowSwitcher-button":
    {
      color: GREY.PRIMARY,
    },
    "& .MuiIconButton-root-MuiPickersArrowSwitcher-button.Mui-disabled": {
      color: "rgba(255,255,255,0.5)",
    },
    "& .MuiPickersCalendarHeader-label": {
      fontWeight: "500",
      fontSize: "clamp(13px,0.833vw,0.833vw)",
    },
    "& .MuiYearPicker-root": {
      ...SCROLL_BAR,
    },
    "& .MuiPickersCalendarHeader-switchViewIcon": {
      display: "none",
    },
    "& .MuiPickersDay-root": {
      "&:focus.Mui-selected": {
        background: (props) => props.background,
      },
    },
  },
  CalendarMobile: {
    "@media  (min-width: 320px) and (max-width: 480px)": {
      width: "100%",
    },
  },
  claendarWrap: {
    background: "#fff",
    borderRadius: "12px",
    marginBottom: "1rem !important",
    position: "relative",
    boxShadow: SHADOW.tags,

    "@media  (min-width: 320px) and (max-width: 480px)": {
      width: "93.2vw",
    },
  },
  loaderClass: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
  previewImage: {
    //display: "contents",
    width: "56px",
    height: "56px",
    objectFit: "contain",
    borderRadius: "50%",
    backgroundColor: "#F1F1F1",
    //alignSelf: "baseline",
  },
  durations: {
    display: "flex",
    flexDirection: "row",
    gap: "10px",
    flexWrap: "wrap",
    margin: "10px 0px 0px 0px",
  },
});
