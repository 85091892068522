import React, { useState, useEffect } from 'react'
import classes from "./DateRangePicker.module.css"
import ClickableCalendar from './ClickableCalendar'
import TypographyInline from '../../Typography/TypographyInline';
import { BRAND, GREY } from '../../../utils/constants/colors';
import { ICON_LABELS, fontSize, fontWeight } from '../../../utils/constants/UI';
import { STRINGS } from '../../strings';
import { IconComponent } from '../../Icon';
import { Button } from '../../Buttons';
import { DATE_RANGE_INDEX_LABELS, DATE_RANGE_LABEL_INDEX } from '../../../utils/constants';
/////////////////// DEFINE CONSTANTS(ACTUAL CONSTANTS NOT CONTROLLED BY STATE)

const minYear = 2023
const maxYear = 2099
const yearList = []
///// construct a ranged list of min and max values of the year state
for (let i = minYear; i <= maxYear; i++) yearList.push(i)


////////////////////////////////////////////////////////////////////
const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
const possibleSelections = ['Today', 'Yesterday', 'Last 7 Days', 'Last 30 Days', 'Custom Range'];
const today = new Date();

const incrementOneMonth = (cur) => {
  if (cur.getMonth() === 11) return new Date(`${cur.getFullYear() + 1}-01-01`);
  return new Date(`${cur.getFullYear()}-${String(cur.getMonth() + 2).padStart(2, '0')}-01`);
}


const DateRangePicker = ({ initDates = {}, onSubmit = () => { }, onReset = () => { } }) => {
  // create states
  const [displayState, setDisplayState] = useState(false); // used to set the diplay state of the two calendars
  const [startDate, setStartDate] = useState(new Date(today)); // these are the main states
  const [endDate, setEndDate] = useState(new Date(today)); // the end date
  const [c1Date, setC1Date] = useState(new Date(today));
  const [c2Date, setC2Date] = useState(incrementOneMonth(today));
  const [isReset, setIsReset] = useState(false);
  const [superDisplayState, setSuperDisplayState] = useState(false);
  const [selectedItem, setSelectedItem] = useState(0);
  const [filterLabel, setFilterLabel] = useState("");


  const stringy = String(startDate.getDate()) + ' ' + (months[startDate.getMonth()]) + ' ' + String(startDate.getFullYear());
  const stringy4 = (endDate === -1) ? '' : `  ${endDate.getFullYear()}/${String(endDate.getMonth() + 1).padStart(2, '0')}/${String(endDate.getDate()).padStart(2, '0')}`;
  const stringy3 = `${startDate.getFullYear()}/${String(startDate.getMonth() + 1).padStart(2, '0')}/${String(startDate.getDate()).padStart(2, '0')} `;
  let stringy2 = "";
  if (endDate !== -1) stringy2 = String(endDate.getDate()) + ' ' + (months[endDate.getMonth()]) + ' ' + String(endDate.getFullYear());

  const handlesRangeSelection = (index) => {
    let twitch = Number(index);
    let label = DATE_RANGE_INDEX_LABELS[parseInt(twitch)];
    setFilterLabel(label);
    setSelectedItem(twitch);
    if (twitch === 4) {
      setDisplayState(true);
    }
    else {
      setDisplayState(false);
      setC1Date(today);
      if (twitch === 0) {
        setStartDate(today);
        setEndDate(today);
        // it is called for sending data to parent component
        applyHandler(DATE_RANGE_INDEX_LABELS[parseInt(twitch)], today, today);
      }
      else if (twitch === 1) {
        let yesterday = new Date(today.getTime() - 86400000);
        setStartDate(yesterday);
        setEndDate(yesterday);
        applyHandler(DATE_RANGE_INDEX_LABELS[parseInt(twitch)], yesterday, yesterday);
      }
      else if (twitch === 2) {
        let weekpast = new Date(today.getTime() - 6 * 86400000);
        setStartDate(weekpast);
        setEndDate(today);
        applyHandler(DATE_RANGE_INDEX_LABELS[parseInt(twitch)], weekpast, today);
      }
      else if (twitch === 3) {
        let _30dayspast = new Date(today.getTime() - 29 * 86400000);
        setStartDate(_30dayspast);
        setEndDate(today);
        applyHandler(DATE_RANGE_INDEX_LABELS[parseInt(twitch)], _30dayspast, today);
      }
      // logic for this month andlast month
      // else if (twitch === 4) {
      //   setStartDate(new Date(`${today.getFullYear()}-${today.getMonth() + 1}-01`));
      //   let x = findMaxDays(today.getMonth() + 1, today.getFullYear());
      //   setEndDate(new Date(`${today.getFullYear()}-${today.getMonth() + 1}-${x}`));
      // }
      // else if (twitch === 5) {
      //   const lastMonth = new Date(today);
      //   lastMonth.setMonth(today.getMonth() - 1);
      //   setStartDate(new Date(lastMonth.getFullYear(), lastMonth.getMonth(), 1));
      //   setEndDate(new Date(today.getFullYear(), today.getMonth(), 0));
      // }

    }
    return;
  }

  const handlesSuperDisplayState = (e) => {
    setSuperDisplayState((cur) => {
      return !cur;
    })
  }

  const applyHandler = (filter_label = "", start_date = "", end_date = "") => {
    let payload = {
      label: filter_label || filterLabel,
      dates: {
        from: start_date || startDate,
        to: end_date || endDate,
      }
    };
    setDisplayState(false); handlesSuperDisplayState();
    onSubmit(payload)
  }

  const resetHandler = () => {
    let payload = {
      label: filterLabel,
      dates: {
        from: today,
        to: today,
      }
    };
    setIsReset(true);
    setStartDate(today);
    setC1Date(today);
    onReset(payload)
  };


  useEffect(() => {
    if (Object.keys(initDates)?.length) {

      let initLabel = DATE_RANGE_LABEL_INDEX?.[initDates?.label] || 0;
      let fromDate = initDates?.dates?.from;
      let toDate = initDates?.dates?.to;
      if (initLabel < 4) handlesRangeSelection(initLabel);
      else {
        setFilterLabel(initDates?.label || "");
        setSelectedItem(initLabel);
        setStartDate(fromDate);
        setEndDate(toDate);
      }
    }
  }, [initDates])

  useEffect(() => {
    if (displayState) {
      if (!isReset) {
        setEndDate(-1);
      }
      else {
        setEndDate(today);
        setIsReset(false);
      }
    }
  }, [startDate]);

  useEffect(() => {
    setC2Date(incrementOneMonth(c1Date));
  }, [c1Date])



  return (
    <div className={classes.superContainer}>
      <div className={classes.fillsWidth}>
        <div className={classes.dateRangeTab} onClick={handlesSuperDisplayState}>
          <div className={classes.showDates}>
            <TypographyInline color={GREY.PRIMARY} label={STRINGS.DATE_COLON} size={"sm"} fontWeight={fontWeight.REGULAR} />
            <TypographyInline color={GREY.SECONDARY} label={` ${stringy} - ${stringy2}`} size={"sm"} fontWeight={fontWeight.REGULAR} />
          </div>
          <IconComponent color={GREY.SECONDARY} fontSize={fontSize.MD} iconLabel={ICON_LABELS.KEYBOARD_ARROW_DOWN} />
        </div>
      </div>
      {(superDisplayState) ? <div id="modal_body" className={classes.dateRangeInterface}>
        <div className={classes.dateRangeInputs}>
          <div className={`${classes.selectionList} ${displayState ? classes.selectionListSmallWidth : classes.selectionListWidth}`}>
            {/* mapping the left part having the choices of pre selected date range. */}
            {possibleSelections.map((item, index) => {
              return (<div key={index} className={(index === selectedItem) ? classes.selectedItem : classes.selectionItem} onClick={() => handlesRangeSelection(index)}>
                <TypographyInline label={item} color={index === selectedItem ? BRAND.PRIMARY : GREY.SECONDARY} size="sm" fontWeight={fontWeight.MEDIUM} isEllipses />
              </div>)
            })}
          </div>

          {(displayState) ? <>
            <div className={classes.customRangeSelector}>
              <div className={classes.containsBothCalendars}>
                <div className={classes.containsCalendar}>
                  <ClickableCalendar initDate={c1Date} minDate={new Date(`1990-01-01`)} startDate={startDate} endDate={endDate} setDate={setC1Date} setStartDate={setStartDate} setEndDate={setEndDate} id="left" />
                </div>
                <div className={classes.containsCalendar}>
                  <ClickableCalendar initDate={c2Date} minDate={incrementOneMonth(c1Date)} startDate={startDate} endDate={endDate} setDate={setC2Date} setStartDate={setStartDate} setEndDate={setEndDate} id="right" />
                </div>
              </div>
            </div>
          </> : <></>}
        </div>
        {
          displayState && <div className={classes.footerContainer}>
            <div>
              <TypographyInline label={stringy3 + `-` + stringy4} color={GREY.SECONDARY} size="sm" fontWeight={fontWeight.REGULAR} isEllipses />
            </div>
            <div className={classes.footerButtonCOntainer} >
              <div><Button label={STRINGS.CANCEL} size="sm36" variant="ghost" onClick={() => { resetHandler() }} /></div>
              <div><Button label={STRINGS.APPLY} size="sm36" variant="primary" onClick={() => { applyHandler() }} /></div>
            </div>
          </div>
        }
      </div> : <></>}
    </div>
  )
}

export default DateRangePicker


// ------------- NOTE ----------------
// Props Description:
// 1. initDates :- it is used to store data into date range picker. It should have format as below:
//      { label: "custom_range", dates: { from: new Date(1710156067620), to: new Date(1710674538372) } }
// Label includes :- today, yesterday, last_7_days, last_30_days,custom_range
// 2. onSubmit :- it is helper which will give you data when clicking apply button.
// 2. onReset :- it is helper which will give you data when clicking cancel button.