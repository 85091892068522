import { axios } from "../config";
import { EMITTER_ERROR } from "../utils/constants";
import { authEndpoints } from "../utils/constants/httpConstants";
import EventEmitter from "../utils/emitter/EventEmitter";
import { INITIAL_ROUTE, INVALID_TENANT_ROUTE_PATH, LOGIN_PATH } from "../utils/constants/routes";
const AUTH_ERROR_CODES = [401, 477];
const TENANT_INACTIVE_CODE = [419]

// Get Request
export const GET = async (url) => {
  try {
    return await axios.get(url);
  } catch (error) {
    throw error?.response;
  }
};

// Post request
export const POST = async (url, payload, navigate = () => { }) => {
  try {
    return await axios.post(url, payload);
  } catch (error) {
    if (TENANT_INACTIVE_CODE.includes(error?.response?.status)) {
      navigate(INVALID_TENANT_ROUTE_PATH);
    }
    if (error?.response?.status === 401) {
      navigate(LOGIN_PATH)
    }
    throw error?.response;
  }
};

// Protected Api's Wrapper
export const getRequest = async (navigate, url) => {
  let response;
  try {
    response = await axios.get(url);
    return response;
  } catch (error) {
    if (AUTH_ERROR_CODES.includes(error?.response?.status)) {
      try {
        let new_response;
        if (!window.refresh_token_calling) {
          window.refresh_token_calling = true;

          await axios.post(authEndpoints.checkLogin);
          window.refresh_token_calling = false;
          const res = await axios.get(url);
          new_response = res;
          // return new_response;
        }

        // now create interval for api call.
        else {
          let api_call_start = false;
          const interval = setInterval(async () => {
            if (!window.refresh_token_calling) {
              if (!api_call_start) {
                api_call_start = true;
                const res = await axios.get(url);
                clearInterval(interval);
                new_response = res;
              }
            }
          }, 50);
        }

        return new_response;

      } catch (err) {
        window.refresh_token_calling = false;
        if (AUTH_ERROR_CODES.includes(err?.response?.status)) {
          // now user is logout;
          EventEmitter.emit(
            EMITTER_ERROR,
            err?.response?.data?.message || err?.message
          );
          navigate(LOGIN_PATH);
        }
        throw err?.response;
      }
    } else if (
      error?.response?.status === 426 ||
      error?.response?.status === 427
    ) {
      navigate(INITIAL_ROUTE);
    } else if (TENANT_INACTIVE_CODE.includes(error?.response?.status)) {
      navigate(INVALID_TENANT_ROUTE_PATH);
    }
    else {
      throw error?.response;
    }
  }
};

export const postRequest = async (navigate, url, payload, headers = {}) => {
  let response;
  try {
    response = await axios.post(url, payload, { headers });
    return response;
  } catch (error) {
    if (AUTH_ERROR_CODES.includes(error?.response?.status)) {
      try {
        let new_response;
        if (!window.refresh_token_calling) {
          window.refresh_token_calling = true;
          await axios.post(authEndpoints.checkLogin);
          window.refresh_token_calling = false;
          const res = await axios.post(url, payload);
          new_response = res;
        }

        // now create interval for api call.
        else {

          let api_call_start = false;
          const interval = setInterval(async () => {
            if (!window.refresh_token_calling) {
              if (!api_call_start) {
                api_call_start = true;
                const res = await axios.post(url, payload, { headers });
                clearInterval(interval);
                new_response = res;
              }
            }
          }, 50);
        }

        return new_response;
      } catch (err) {
        window.refresh_token_calling = false;

        if (AUTH_ERROR_CODES.includes(err?.response?.status)) {
          // now user is logout;
          EventEmitter.emit(
            EMITTER_ERROR,
            err?.response?.data?.message || err?.message
          );
          navigate(LOGIN_PATH);
        }
        throw err?.response;
      }
    } else if (error?.response?.status === 426) {
      navigate(INITIAL_ROUTE);
    } else if (TENANT_INACTIVE_CODE.includes(error?.response?.status)) {
      navigate(INVALID_TENANT_ROUTE_PATH);
    } else {
      throw error?.response;
    }
  }
};

export const putRequest = async (navigate, url, payload) => {
  let response;
  try {
    response = await axios.put(url, payload);
    return response;
  } catch (error) {
    if (AUTH_ERROR_CODES.includes(error?.response?.status)) {
      try {
        await axios.post(authEndpoints.checkLogin);
        const res = await axios.put(url, payload);
        return res;
      } catch (err) {
        if (AUTH_ERROR_CODES.includes(err?.response?.status)) {
          // now user is logout;
          EventEmitter.emit(
            EMITTER_ERROR,
            err?.response?.data?.message || err?.message
          );
          navigate(LOGIN_PATH);
        }
        else if (TENANT_INACTIVE_CODE.includes(error?.response?.status)) {
          navigate(INVALID_TENANT_ROUTE_PATH);
        }
        throw error?.response;
      }
    } else {
      throw error?.response;
    }
  }
};

export const patchRequest = async (navigate, url, payload) => {
  let response;
  try {
    response = await axios.patch(url, payload);
    return response;
  } catch (error) {
    if (AUTH_ERROR_CODES.includes(error?.response?.status)) {
      try {
        await axios.post(authEndpoints.checkLogin);
        const res = await axios.patch(url, payload);
        return res;
      } catch (err) {
        if (AUTH_ERROR_CODES.includes(err?.response?.status)) {
          // now user is logout;
          EventEmitter.emit(
            EMITTER_ERROR,
            err?.response?.data?.message || err?.message
          );
          navigate(LOGIN_PATH);
        }
        else if (TENANT_INACTIVE_CODE.includes(error?.response?.status)) {
          navigate(INVALID_TENANT_ROUTE_PATH);
        }
        throw error?.response;
      }
    } else {
      throw error?.response;
    }
  }
};

export const deleteRequest = async (url, payload, navigate) => {
  let response;
  try {
    response = await axios.delete(url, { data: payload });
    return response;
  } catch (error) {
    if (AUTH_ERROR_CODES.includes(error?.response?.status)) {
      try {
        await axios.post(authEndpoints.checkLogin);
        const res = await axios.delete(url, { data: payload });
        return res;
      } catch (err) {
        if (AUTH_ERROR_CODES.includes(err?.response?.status)) {
          // now user is logout;
          EventEmitter.emit(
            EMITTER_ERROR,
            err?.response?.data?.message || err?.message
          );
          navigate(LOGIN_PATH)
        }
        throw error?.response;
      }
    } else if (TENANT_INACTIVE_CODE.includes(error?.response?.status)) {
      navigate(INVALID_TENANT_ROUTE_PATH);
    }
    else {
      throw error?.response;
    }
  }
};
