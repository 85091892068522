import React, { useEffect, useState } from "react";
import { fontSize, fontWeight } from "../../utils/constants/UI/uiConstants";
import classes from "./AutoComplete.module.css";
import SearchField from "../InputFields/SearchField";
import { BRAND, GREY, PRIMARY } from "../../utils/constants/colors";
import { Menu, MenuItem } from "@mui/material";
import Checkbox from "../Buttons/Checkbox/Checkbox";
import { ICON_LABELS } from "../../utils/constants/UI";
import TypographyInline from "../Typography/TypographyInline";
import IconComponent from "../Icon/IconComponent";
import { STRINGS } from "../strings";
import Image from "../Image/Image";
import { debounceHelper } from "../../utils/helpers";
import { DEBOUNCE_DELAY } from "../../utils/constants";

const SIZE_MAPPING = {
  xs34: {
    padding: "8px 10px",
  },
  sm34: {
    padding: "8px 10px",
  },
  sm36: {
    padding: "8px 10px",
  },
  md40: {
    padding: "10px",
  },
  lg44: {
    padding: "10px",
  },
};

export const AutoCompleteCustom = ({
  items = [],
  subItems = [],
  subItemsLabel = "Sub Label",
  multiple = false,
  isLoading = false,
  size = "sm36",
  valueState = [],
  setValueState = () => { },
  idState = [],
  setIdState = () => { },
  handleClick = () => { },
  paperWidth = "",
  dropdownId = "",
  onChange,
  isColor = false,
  isSearchEnable = false,
  handleSearch = () => { },
  LeftImage = "",
  RightImage = "",
  ...props
}) => {
  const styles = {
    dropdownMainContainer: {
      width: "100%",
      cursor: "pointer",
    },
    paperMenu: {
      maxHeight: props?.paperHeight || "30vh",
      width:
        paperWidth ||
        document?.getElementById(dropdownId || "custom-dropdown")?.clientWidth,
      marginTop: "3px",
      boxShadow:
        "0px 12px 8px 0px rgba(24, 24, 28, 0.02), 0px 0px 16px 0px rgba(24, 24, 28, 0.12)",
    },
    selectedItem: {
      padding: "0px",
      backgroundColor: PRIMARY.W_50,
      gap: "4px",
      boxShadow: `inset 2px 0px 0px 0px ${BRAND.PRIMARY}`,
    },
    defaultItem: {
      padding: "0px",
      gap: "4px",
    },
    noRecordFoundLabel: {
      padding: "0px 8px"
    },
    checkboxContainer: {
      padding: multiple && "8px 0px 10px 8px",
    },
    labelContainer: {
      padding: multiple ? "8px 0px 10px 8px" : SIZE_MAPPING[size]?.padding || "8px 10px",
      width: "100%",
    },
    labelContainerWithLeftItem: {
      padding: multiple ? "8px 0px 10px 8px" : SIZE_MAPPING[size]?.padding || "8px 10px",
      paddingLeft: "0px",
      width: "100%",
    },
    leftItemList: {
      paddingLeft: "10px",
      display: "flex",
      alignItems: "center"
    }
  };
  const [isVisible, setIsVisible] = useState(false);
  const [anchor, setAnchor] = useState(null);

  const [leftImageSrc, setleftImageSrc] = useState("");
  const [rightImageSrc, setrightImageSrc] = useState("");

  useEffect(() => {
    if (LeftImage) {
      setleftImageSrc(LeftImage);
    }
    if (LeftImage === undefined) {
      setleftImageSrc("");
    }
    if (RightImage) {
      setrightImageSrc(RightImage);
    }
  }, [LeftImage, RightImage]);

  const onClickHandler = (label, id) => {
    if (LeftImage) {
      const dataFilter = items?.filter((item) => item?.id === id);
      setleftImageSrc(dataFilter[0]?.iconSrc);
    } else if (RightImage) {
      const dataFilter = items?.filter((item) => item?.id === id);
      setrightImageSrc(dataFilter[0]?.iconSrc);
    }
    if (multiple) {
      if (valueState?.includes(label) && idState?.includes(id)) {
        let newSelectedValue = valueState?.filter((val) => val !== label);
        let newSelectedId = idState?.filter((val) => val !== id);
        setValueState(newSelectedValue);
        setIdState(newSelectedId);
        if (onChange) {
          onChange({ id: newSelectedId, label: newSelectedValue });
        }
      } else {
        setValueState([...valueState, label]);
        setIdState([...idState, id]);
        if (onChange) {
          onChange({
            id: [...idState, id],
            label: [...valueState, label],
          });
        }
      }
    } else {
      setValueState([label]);
      setIdState([id]);
      setIsVisible(false);
      if (onChange) {
        onChange({ id: [id], label: [label] });
      }
    }
  };

  const debounceSearch = debounceHelper(handleSearch, DEBOUNCE_DELAY);

  return (
    <div
      style={styles.dropdownMainContainer}
      className={classes.dropdownMainContainer}
      id={dropdownId || "custom-dropdown"}
    >
      <SearchField
        onClick={(e) => {
          if (props?.variant !== "disabled") {
            handleClick();
            setIsVisible(true);
            setAnchor(e?.currentTarget);
          }
        }}
        size={size}
        value={valueState}
        placeholder={props?.placeholder || "Select"}
        RightIcon={ICON_LABELS.ACTION_ARROW}
        LeftIcon=""
        isColor={isColor}
        readOnly={true}
        id={classes.inputField}
        {...props}
        LeftImage={leftImageSrc}
        RightImage={rightImageSrc}
      />
      {isVisible && (
        <Menu
          onClose={() => setIsVisible(false)}
          anchorEl={anchor}
          open={isVisible}
          PaperProps={{ style: styles.paperMenu, id: "modal_body" }}
        >
          {isSearchEnable && (
            <div className={classes.searchFieldContainer}>
              <SearchField
                size={"sm36"}
                onChange={(event) => {
                  debounceSearch(event);
                }}
                onKeyDown={(event) => event.stopPropagation()}
                placeholder={STRINGS.SEARCH}
              />
            </div>
          )}
          {isLoading ? (
            <div style={styles.defaultItem}>
              <TypographyInline
                label={"Loading..."}
                color={GREY.SECONDARY}
                fontWeight={fontWeight.REGULAR}
              />
            </div>
          ) : (
            <div>
              {items?.length ? (
                <div>
                  {items?.map((item, index) => {
                    return item?.label?.toString()?.trim()?.length ? (
                      <MenuItem
                        style={
                          idState?.filter(
                            (val) => val === (item._id || item?.id)
                          )?.length
                            ? styles.selectedItem
                            : styles.defaultItem
                        }
                        className={
                          idState?.filter(
                            (val) => val === (item._id || item?.id)
                          )?.length
                            ? classes.selectedItem
                            : classes.defaultItem
                        }
                        key={index}
                      // onClick={() =>
                      //   onClickHandler(item?.label, item?.id || item?._id)
                      // }
                      >
                        {multiple && (
                          <div style={styles.checkboxContainer}>
                            <Checkbox
                              size={fontSize.SM}
                              checked={idState?.includes(item?.id || item?._id)}
                              onChange={(e) => {
                                e?.stopPropagation();
                                onClickHandler(
                                  item?.label,
                                  item?.id || item?._id
                                );
                              }}
                            />
                          </div>
                        )}
                        {item?.iconLabel && (
                          <div style={styles.leftItemList} >
                            <IconComponent
                              fontSize={fontSize.MD}
                              color={item?.iconColor || GREY.TERTIARY}
                              iconLabel={item?.iconLabel}
                              onClick={(e) => {
                                e?.stopPropagation();
                                onClickHandler(
                                  item?.label,
                                  item?.id || item?._id
                                );
                              }}
                            />
                          </div>
                        )}
                        {item?.iconSrc && (
                          <div style={styles.leftItemList} ><Image
                            src={item?.iconSrc}
                            onClick={(e) => {
                              e?.stopPropagation();
                              onClickHandler(
                                item?.label,
                                item?.id || item?._id
                              );
                            }}
                          /></div>
                        )}
                        <div style={item?.iconLabel || item?.iconSrc ? styles.labelContainerWithLeftItem : styles.labelContainer} onClick={() =>
                          onClickHandler(item?.label, item?.id || item?._id)
                        }>
                          <TypographyInline
                            label={item?.label?.toString() || ""}
                            size={size.slice(0, 2)}
                            fontWeight={fontWeight.REGULAR}
                            color={GREY.SECONDARY}
                          // onClick={() =>
                          //   onClickHandler(item?.label, item?.id || item?._id)
                          // }
                          />
                        </div>
                      </MenuItem>
                    ) : null;
                  })}
                </div>
              ) : (
                <div style={styles.noRecordFoundLabel}>
                  <TypographyInline
                    color={GREY.PRIMARY}
                    size={"sm"}
                    label={STRINGS.NO_RECORD_FOUND}
                    fontWeight={fontWeight.REGULAR}
                  />
                </div>
              )}

              {/* Looping when subItems are available. */}
              {subItems?.length ? (
                <>
                  <div className={classes.subItemLabel}>
                    <TypographyInline
                      color={GREY.PRIMARY}
                      label={subItemsLabel}
                      size={"sm"}
                      fontWeight={fontWeight.SEMIBOLD}
                      isEllipses
                    />
                  </div>
                  {subItems?.map((item, index) => {
                    return item?.label?.trim()?.length ? (
                      <>
                        <MenuItem
                          style={
                            idState?.filter(
                              (val) => val === (item._id || item?.id)
                            )?.length
                              ? styles.selectedItem
                              : styles.defaultItem
                          }
                          className={
                            idState?.filter(
                              (val) => val === (item._id || item?.id)
                            )?.length
                              ? classes.selectedItem
                              : classes.defaultItem
                          }
                          key={index}
                          onClick={() =>
                            onClickHandler(item?.label, item?.id || item?._id)
                          }
                        >
                          {multiple && (
                            <div style={styles.checkboxContainer}>
                              <Checkbox
                                size={fontSize.SM}
                                checked={valueState?.includes(item?.label)}
                                onChange={(e) => {
                                  e?.stopPropagation();
                                  onClickHandler(
                                    item?.label,
                                    item?.id || item?._id
                                  );
                                }}
                              />
                            </div>
                          )}
                          {item?.iconLabel && (
                            <IconComponent
                              fontSize={fontSize.MD}
                              color={item?.iconColor || GREY.TERTIARY}
                              iconLabel={item?.iconLabel}
                              onClick={(e) => {
                                e?.stopPropagation();
                                onClickHandler(
                                  item?.label,
                                  item?.id || item?._id
                                );
                              }}
                            />
                          )}
                          <div style={styles.labelContainer}>
                            <TypographyInline
                              label={item?.label || ""}
                              size={size.slice(0, 2)}
                              fontWeight={fontWeight.REGULAR}
                              color={GREY.SECONDARY}
                            // onClick={() =>
                            //   onClickHandler(
                            //     item?.label,
                            //     item?.id || item?._id
                            //   )
                            // }
                            />
                          </div>
                        </MenuItem>
                      </>
                    ) : null;
                  })}
                </>
              ) : null}
            </div>
          )}
        </Menu>
      )}
    </div>
  );
};

// ------------- NOTE ----------------
// If there is need to add color or any other attribute in button then please use SIZE_MAPPING object.
// Add key into object and use it with props according to size or variant.
// Props description :-
// items :- This should contain values for dropdown to display in the form of array of object have id, label and iconLabel(pass this if you want icon on the left side of dropdown value) as key of objects.
// subItems :- This should contain values for dropdown to display as a items of sublabel in the form of array of object have id and labes as key of objects.
// subItemsLabel :- This is label which will be displayed as a sublabel for subitems.
// multiple :- Will make dropdown as multiple.
// isLoading :- Loading state of dropdown.
// size :- size of dropdown mentioned in figma.
// paperWidth: "This prop is used for menu paper width."
// handleClick :- This prop is onClick function on search field.
// dropdownId :- This prop is used for defining unique id for dropdown.
// onChange :- It is on change function for dropdown.
// isSearchEnable :- This prop will enable search for searching values in dropdown.
// handleSearch :- This prop should be pass from parent component having searching logic for dropdown items.
// These below four props are used for values and id of selected value in dropdown. And these must be passed from parent component.
// valueState :- state for selected label
// setValueState :- set state for selected label.
// idState :- state for selected id.
// setIdState :- set state for selected id.
