import { styled } from "@mui/material/styles";
import { autocompleteClasses } from "@mui/material/Autocomplete";
import { SCROLL_BAR } from "../../utils/constants/UI/uiConstants";
import { BASE, BRAND, PRIMARY } from "../../utils/constants/colors";
import { innerLeftBorder } from "../../utils/helpers";

export const Listbox = styled("ul")(({ theme }) => ({
  margin: 0,
  padding: 0,
  zIndex: 1,
  position: "absolute",
  listStyle: "none",
  backgroundColor: theme.palette.background.paper,
  overflow: "auto",
  maxHeight: 200,
  borderRadius: "4px",
  border: "1px solid #d3dae3",
  ...SCROLL_BAR,
  "& li": {
    padding: "5px 12px",
    fontWeight: "400",
    color: "#5F5F61",
    fontSize: "14px",
  },
  [`& li.${autocompleteClasses.focused}`]: {
    backgroundColor: BASE.BACKGROUND_LIGHT,
    color: "#5F5F61",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: BASE.BACKGROUND_LIGHT,
      boxShadow: innerLeftBorder(BRAND.PRIMARY),
    },
  },
  "& li:active": {
    // backgroundColor: "#ff4848",
    color: "#5F5F61",
    backgroundColor: PRIMARY.W_50,
    "&:hover": {
      backgroundColor: BASE.BACKGROUND_LIGHT,
      boxShadow: innerLeftBorder(BRAND.PRIMARY),
    },
  },
}));
