export const styles = {
  appliedFilterPara: {
    fontSize: "13px",
    color: "#626B7E",
    fontWeight: "400",
    alignSelf: "center",
  },
  eachAppliedFilter: {
    fontWeight: "600",
    fontSize: "13px",
  },
  selectedFilterName: {
    color: "#ff4848",
    fontWeight: "600",
    fontSize: "13px",
    textTransform: "capitalize",
  },
  selectedFilterBox: {
    padding: "10px 12px",
    display: "flex",
    alignItems: "center",
    gap: "4px",
  },
  selectedDynamicFilterBox: {
    padding: "10px 12px",
    display: "flex",
    alignItems: "center",
    gap: "4px",
    backgroundColor: "#f8f8f8"
  },
  cutSelected: {
    alignSelf: "center",
    margin: "0 5px",
  },
  crossIcon: {
    alignSelf: "center",
    color: "#626b7e",
    fontSize: "18px !important",
    display: "flex !important",
    cursor: "pointer",
  },
  filterValues: {
    display: "flex",
    alignItems: "center",
    gap: "6px",
    flexWrap: "wrap",
  },
  cancelIcon: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
  },
};
