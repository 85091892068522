import React from "react";
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/system";
import { ERROR } from "../constants/assets";
import SuspenseImage from "../../components/Image/SuspenseImage";
import { STRINGS } from "./strings";
import Typography from "../../components/Typography/Typography";
import { GREY } from "../constants/colors";
import { fontWeight } from "../constants/UI";
const styles = makeStyles({
  Header: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
    height: "100vh",
  },
});
const errorPage = () => {
  const classes = styles();

  return (
    <>
      <Box className={classes.Header}>
        <SuspenseImage src={ERROR} />

        <Box>
          {" "}
          <Typography
            label={STRINGS.ERROR}
            color={GREY.TERTIARY}
            size={"sm"}
            fontWeight={fontWeight.REGULAR}
          />{" "}
        </Box>
        <Box>
          {" "}
          <Typography
            label={STRINGS.GOOGLE_REGISTER}
            color={GREY.TERTIARY}
            size={"sm"}
            fontWeight={fontWeight.REGULAR}
          />{" "}
        </Box>
        {/* <Box>{STRINGS.GOOGLE_REGISTER}</Box> */}
      </Box>
    </>
  );
};

export default errorPage;
