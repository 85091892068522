import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import CloseIcon from "@mui/icons-material/Close";

function Tag(props) {
  const { label, onDelete, ...other } = props;
  return (
    <div {...other}>
      <span>{label}</span>
      <CloseIcon onClick={onDelete} />
    </div>
  );
}

Tag.propTypes = {
  label: PropTypes.string.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export const StyledTag = styled(Tag)(
  ({ theme }) => `
    display: flex;
    padding:4px !important;
    align-items: center; 
    align-self:center;
    height: 24px;
    margin: 8px 0px 8px 8px;
    line-height: 22px;
    background-color: ${
      theme.palette.mode === "dark" ? "rgba(255,255,255,0.08)" : "#d3dae3"
    };
    border: none;
    border-radius: 4px;
    box-sizing: content-box;
    padding: 0 4px 0 10px;
    outline: 0;
    overflow: hidden;
  
    &:focus {
      border-color: ${theme.palette.mode === "dark" ? "#177ddc" : "#40a9ff"};
      background-color: ${
        theme.palette.mode === "dark" ? "#003b57" : "#e6f7ff"
      };
    }
  
    & span {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      font-family:400;
      color:#626B7E;
      font-size: 12px;
    }
  
    & svg {
      font-size: 20px;
      cursor: pointer;
      padding: 4px;
      color:#626B7E;
    }
  `
);
